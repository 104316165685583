import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import en from "./locale/en";
import zhHans from "./locale/zh-Hans";

const messages = {
  // en,
  cn: zhHans
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "cn",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "cn",
  messages
});
