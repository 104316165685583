<template>
  <div class="pl-6">
    <!-- <button @click="$emit('return')">
      <img src="/img/toolbar/Return.svg" />
      <label>{{ $t("ReturnTooltip") }}</label>
    </button> -->

    <button @click="createCrossSection">
      <img src="/img/toolbar/CreateCrossSection.svg" />
      <label>{{ $t("CreateCrossSectionTooltip") }}</label>
    </button>

    <button @click="changeCrossSectionAxis">
      <img :src="axisIcon" />
      <label>{{ $t("CrossSectionAxisTooltip") }}</label>
    </button>

    <button @click="changeCrossSectionSide">
      <img :src="sideIcon" />
      <label>{{ $t("ClipModeTooltip") }}</label>
    </button>

    <button
      @click="removeCrossSection"
      :disabled="selectedPlaneId === 'NONE' ? true : false"
    >
      <img src="/img/toolbar/DeleteCrossSection.svg" />
      <label>{{ $t("DeleteCrossSectionTooltip") }} </label>
    </button>

    <button @click="removeAllCrossSections">
      <img src="/img/toolbar/DeleteAllCrossSections.svg" />
      <label>{{ $t("DeleteAllCrossSectionsTooltip") }}</label>
    </button>
  </div>
</template>

<script>
import SectionHandle from "./../../assets/SGOs/SectionHandle.module.js";
import { mapState, mapGetters } from "vuex";

// import { SectionSide } from "@com.siemens.plm.web/PLMVisWeb";
const PLMVisWeb = require("@com.siemens.plm.web/PLMVisWeb");
const SectionSide = PLMVisWeb.SectionSide;

export default {
  data: () => ({
    selectedPlaneId: "NONE",
    dir: [1, 0, 0],
    side: SectionSide.OFF,
    axisIcon: "/img/toolbar/CrossSectionX.svg",
    sideIcon: "/img/toolbar/ClipNone.svg",
    sectionHandle: null
  }),
  computed: {
    ...mapState(["touchScreenDevice"]),
    ...mapGetters(["viewerManager", "sectionManager", "sgoManager"])
  },
  mounted: function () {
    this.sectionManager.registerPlaneSelectionEvent(
      this.sectionPlaneSelectionHandler
    );
    this.sectionManager.defaults.showEdges = true;
    this.sectionHandle = new SectionHandle();
    this.sectionHandle.manager = this.sectionManager;
    this.sgoManager.addSGO(this.sectionHandle);
  },
  methods: {
    sectionPlaneSelectionHandler: function (evt) {
      this.selectedPlaneId = evt.selectedPlane;
    },
    createCrossSection: function () {
      if (this.selectedPlaneId === "NONE") {
        let center = this.viewerManager.getVisibleModelBoundingBoxCenter();
        this.selectedPlaneId = this.sectionManager.addSectionPlane(
          center[0],
          center[1],
          center[2],
          this.dir[0],
          this.dir[1],
          this.dir[2],
          this.side
        );
      }
    },
    changeCrossSectionAxis: function () {
      if (this.dir[0] === 1 && this.dir[1] === 0 && this.dir[2] === 0) {
        this.dir = [0, 1, 0];
        this.axisIcon = "/img/toolbar/CrossSectionY.svg";
      } else if (this.dir[0] === 0 && this.dir[1] === 1 && this.dir[2] === 0) {
        this.dir = [0, 0, 1];
        this.axisIcon = "/img/toolbar/CrossSectionZ.svg";
      } else if (this.dir[0] === 0 && this.dir[1] === 0 && this.dir[2] === 1) {
        this.dir = [1, 0, 0];
        this.axisIcon = "/img/toolbar/CrossSectionX.svg";
      }
      this.updateSection();
      this.updateSectionHandle();
    },
    changeCrossSectionSide: function () {
      if (this.side === SectionSide.OFF) {
        this.side = SectionSide.POSITIVE;
        this.sideIcon = "/img/toolbar/ClipNear.svg";
      } else if (this.side === SectionSide.POSITIVE) {
        this.side = SectionSide.NEGATIVE;
        this.sideIcon = "/img/toolbar/ClipFar.svg";
      } else if (this.side === SectionSide.NEGATIVE) {
        this.side = SectionSide.OFF;
        this.sideIcon = "/img/toolbar/ClipNone.svg";
      }
      this.updateSection();
      this.updateSectionHandle();
    },
    removeCrossSection: function () {
      if (this.selectedPlaneId !== "NONE") {
        this.sectionManager.removeSectionPlane(this.selectedPlaneId);
        this.selectedPlaneId = "NONE";
      }
    },
    removeAllCrossSections: function () {
      this.sectionManager.clear();
      this.selectedPlaneId = "NONE";
    },
    updateSection: function () {
      if (this.selectedPlaneId !== "NONE") {
        this.sectionManager.setSectionDirection(
          this.selectedPlaneId,
          this.dir[0],
          this.dir[1],
          this.dir[2]
        );
        this.sectionManager.setSectionSide(this.selectedPlaneId, this.side);
      }
    },
    updateSectionHandle: function () {
      if (this.selectedPlaneId !== "NONE") {
        var sectionSide = this.sectionManager.getSectionSide(
          this.selectedPlaneId
        );
        var handleDir = [].concat(this.dir);
        if (sectionSide === SectionSide.NEGATIVE) {
          handleDir[0] = -handleDir[0];
          handleDir[1] = -handleDir[1];
          handleDir[2] = -handleDir[2];
        }
        this.sectionHandle.setDirection(
          handleDir[0],
          handleDir[1],
          handleDir[2]
        );
      }
    }
  }
};
</script>

<style scoped>
.v-list-item--disabled {
  background-color: gray;
}
</style>
