<template>
  <div class="pl-6">
    <!-- <button @click="$emit('return')">
      <img src="/img/toolbar/Return.svg" />
      <label>{{ $t("ReturnTooltip") }}</label>
    </button> -->

    <button @click="showHideAllPmi">
      <img :src="showHideAllPmiIcon" />
      <label>{{ $t(showHideAllPmiText) }}</label>
    </button>

    <button @click="hideSelectedPmi" :disabled="!selectedPmi">
      <img src="/img/toolbar/HidePMI.svg" />
      <label>{{ $t("HidePMITooltip") }}</label>
    </button>

    <button @click="alignSelectedPmi" :disabled="!selectedPmi">
      <img src="/img/toolbar/AlignToView.svg" />
      <label>{{ $t("AlignSelectedPMITooltip") }}</label>
    </button>

    <button @click="openPMIFilters">
      <img src="/img/toolbar/Filters.svg" />
      <label>{{ $t("PMIFiltersTooltip") }}</label>
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    showHideAllPmiIcon: "/img/toolbar/ShowAllPMI.svg",
    showHideAllPmiText: "ShowAllPMITooltip",
    selectedPmi: ""
  }),
  computed: {
    ...mapState(["touchScreenDevice", "pmiFiltersMap"]),
    ...mapGetters(["viewerManager", "pmiManager"])
  },
  mounted: function () {
    this.$root.$on("modelLoaded", this.refreshMenu);
  },
  methods: {
    ...mapMutations(["setSideDrawer", "setPmiActive"]),
    refreshMenu: function (psId) {
      this.showHideAllPmiIcon = "/img/toolbar/ShowAllPMI.svg";
      this.showHideAllPmiText = "ShowAllPMITooltip";
      this.resetPmiFilters();
      this.processPmi(psId);
    },
    resetPmiFilters: function () {
      for (var filter in this.pmiFiltersMap) {
        this.pmiFiltersMap[filter].psIds = [];
      }
    },
    processPmi: function (psId) {
      var nodeData = this.pmiManager.getPmiStructureInfo(psId, [], 2);
      var nodeChildren = nodeData.children;
      if (nodeChildren) {
        for (var child of nodeChildren) {
          if (child.psId.endsWith(child.name)) {
            var currentType;
            if (this.pmiFiltersMap[child.name]) {
              currentType = child.name;
            } else {
              currentType = "Other";
            }
            for (var grandChild of child.children) {
              this.pmiFiltersMap[currentType].psIds.push(grandChild.psId);
              this.pmiManager.registerSelectionChangeEventByPsId(
                grandChild.psId,
                (function (ctx, psId) {
                  return function (selectionEvent) {
                    ctx.handleSelectionChangeEvent(psId, selectionEvent);
                  };
                })(this, grandChild.psId)
              );
            }
          } else {
            this.processPmi(child.psId);
          }
        }
      }
    },
    showHideAllPmi: function () {
      if (this.showHideAllPmiText === "ShowAllPMITooltip") {
        for (var filter1 in this.pmiFiltersMap) {
          if (
            this.pmiFiltersMap[filter1].psIds.length > 0 &&
            this.pmiFiltersMap[filter1].selected === true
          ) {
            this.pmiManager.setVisibilityByPsId(
              this.pmiFiltersMap[filter1].psIds,
              true
            );
          }
        }
        this.showHideAllPmiIcon = "/img/toolbar/HideAllPMI.svg";
        this.showHideAllPmiText = "HideAllPMITooltip";
        this.setPmiActive(true);
      } else {
        for (var filter2 in this.pmiFiltersMap) {
          if (this.pmiFiltersMap[filter2].psIds.length > 0) {
            this.pmiManager.setVisibilityByPsId(
              this.pmiFiltersMap[filter2].psIds,
              false
            );
          }
        }
        this.showHideAllPmiIcon = "/img/toolbar/ShowAllPMI.svg";
        this.showHideAllPmiText = "ShowAllPMITooltip";
        this.setPmiActive(false);
      }
    },
    hideSelectedPmi: function () {
      if (this.selectedPmi) {
        var selected = this.selectedPmi;
        this.pmiManager.setSelectionByPsId(selected, false);
        this.pmiManager.setVisibilityByPsId(selected, false);
      }
    },
    alignSelectedPmi: function () {
      if (this.selectedPmi) {
        this.pmiManager.alignCameraToPmi(this.selectedPmi);
      }
    },
    openPMIFilters: function () {
      this.setSideDrawer("pmiFilters");
    },
    handleSelectionChangeEvent: function (psId, selectionEvent) {
      if (selectionEvent) {
        this.selectedPmi = psId;
      } else {
        if (this.selectedPmi === psId) {
          this.selectedPmi = "";
        }
      }
    }
  }
};
</script>

<style scoped></style>
