var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center justify-between p-2 mb-4"},[_c('label',{staticClass:"text-lg font-bold"},[_vm._v(_vm._s(_vm.$t("ModelViewsTitle")))]),_c('img',{staticClass:"h-6 w-6 cursor-pointer",attrs:{"src":"/img/toolbar/Close.svg"},on:{"click":function($event){return _vm.setSideDrawer(null)}}})]),_c('div',[(_vm.headerNode)?_c('button',{on:{"click":_vm.previousLevel}},[(_vm.headerNode.previousPsId !== '-1')?_c('img',{attrs:{"src":"/img/toolbar/Return.svg"}}):_vm._e(),_c('label',{class:[
          'font-bold',
          _vm.headerNode.previousPsId !== '-1' ? '-ml-2' : ''
        ],staticStyle:{"cursor":"default"}},[_vm._v(" "+_vm._s(_vm.headerNode.name)+" ")])]):_vm._e(),_vm._l((_vm.nodes),function(node){return _c('button',{key:node.psId,class:{
        'active-class': node.psId === _vm.selectedModelView && !node.children
      },on:{"click":function($event){node.children
          ? _vm.loadModelViewLevel(node.psId)
          : _vm.setSelection(node.psId)}}},[(_vm.thumbnailData && _vm.thumbnailData[node.psId])?_c('img',{staticStyle:{"width":"5rem","height":"5rem"},attrs:{"src":_vm.thumbnailData[node.psId]}}):_vm._e(),_c('label',[_vm._v(_vm._s(node.name))]),(node.children)?_c('img',{attrs:{"src":"/img/toolbar/ChevronOpen.svg"}}):_vm._e()])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }