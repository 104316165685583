export default {
  badge: "徽章",
  close: "关闭",
  dataIterator: {
    noResultsText: "没有符合条件的结果",
    loadingText: "加载中……"
  },
  dataTable: {
    itemsPerPageText: "每页数目：",
    ariaLabel: {
      sortDescending: "：降序排列。",
      sortAscending: "：升序排列。",
      sortNone: "：未排序。",
      activateNone: "点击以移除排序。",
      activateDescending: "点击以降序排列。",
      activateAscending: "点击以升序排列。"
    },
    sortBy: "排序方式"
  },
  dataFooter: {
    itemsPerPageText: "每页数目：",
    itemsPerPageAll: "全部",
    nextPage: "下一页",
    prevPage: "上一页",
    firstPage: "首页",
    lastPage: "尾页",
    pageText: "{0}-{1} 共 {2}"
  },
  datePicker: {
    itemsSelected: "已选择 {0}",
    nextMonthAriaLabel: "下个月",
    nextYearAriaLabel: "明年",
    prevMonthAriaLabel: "前一个月",
    prevYearAriaLabel: "前一年"
  },
  noDataText: "没有数据",
  carousel: {
    prev: "上一张",
    next: "下一张",
    ariaLabel: {
      delimiter: "Carousel slide {0} of {1}"
    }
  },
  calendar: {
    moreEvents: "还有 {0} 项"
  },
  fileInput: {
    counter: "{0} 个文件",
    counterSize: "{0} 个文件（共 {1}）"
  },
  timePicker: {
    am: "AM",
    pm: "PM"
  },
  pagination: {
    ariaLabel: {
      wrapper: "分页导航",
      next: "下一页",
      previous: "上一页",
      page: "转到页面 {0}",
      currentPage: "当前页 {0}"
    }
  },

  /**
   *
   * zh-Hans
   *
   *
   */
  Home: "主页",
  OpenFile: "打开文件",
  LoadFile: "加载文件",
  LoadFromURL: "从 URL 加载",
  PleaseEnterURL: "请输入 URL",
  Close: "关闭",
  Load: "加载",
  RecentFiles: "最近的文件",
  SampleFiles: "示例文件",
  ReturnTooltip: "返回",
  SecurityDialogTitle: "认可安全标志",
  FileInvalidDialogTitle: "文件无效",
  LoadErrorDialogTitle: "加载出错",
  Back: "后退",
  Continue: "继续",
  Acknowledge: "认可",
  ProductStructureTooltip: "产品结构",
  ProductStructureTitle: "产品结构",
  ModelViewsTooltip: "模型视图",
  ModelViewsTitle: "模型视图",
  PropertiesTooltip: "属性",
  PropertiesTitle: "属性",
  PMITooltip: "PMI",
  ShowAllPMITooltip: "显示所有 PMI",
  HideAllPMITooltip: "隐藏所有 PMI",
  HidePMITooltip: "隐藏选定 PMI",
  AlignSelectedPMITooltip: "对齐所选 PMI",
  PMIFiltersTooltip: "PMI 过滤器",
  PMIFiltersTitle: "PMI 过滤器",
  PMIFiltersDescription: "选择要显示的 PMI 类型",
  SelectAll: "全选",
  CrossSectionTooltip: "横截面",
  CreateCrossSectionTooltip: "创建横截面",
  CrossSectionAxisTooltip: "更改轴",
  ClipModeTooltip: "更改近侧/远侧",
  DeleteCrossSectionTooltip: "删除横截面",
  DeleteAllCrossSectionsTooltip: "全部删除",
  MarkupTooltip: "批注",
  FreehandTooltip: "手绘图",
  AnchoredTextTooltip: "锚定文本",
  EraseTooltip: "擦除",
  ShowMarkupViewsTooltip: "显示批注视图",
  MarkupViewsTitle: "批注视图",
  AddMarkupViewTooltip: "添加批注视图",
  EditMarkupDescriptionTooltip: "编辑描述",
  AcceptMarkupDescriptionTooltip: "接受更改",
  DeleteMarkupViewTooltip: "删除批注视图",
  SnapshotTooltip: "快照",
  FitTooltip: "自动居中",
  Settings: "设置",
  Language: "语言",
  InformationOverlayPosition: "信息重叠位置",
  NavCubePosition: "NavCube 位置",
  TriadPosition: "三重轴位置",
  BackgroundColor: "背景色",
  CameraOnOff: "摄像机开/关",
  CameraBackground: "摄像机背景",
  SelectionColor: "选定颜色",
  PMISelectionColor: "PMI 选定颜色",
  PMIColor: "PMI 颜色",
  Default: "默认",
  ViewType: "视图类型",
  Perspective: "透视",
  Orthographic: "正交",
  ClearRecentFiles: "清除最近的文件",
  RestoreDefaultSettings: "恢复默认设置",
  Help: "帮助",
  Legal: "法律信息",
  PrivacyPolicy: "隐私策略",
  RestoreDefaults: "恢复默认值",

  /**
   * Add more
   *
   */

  Viewer: "Light 3D Viewer"
};
