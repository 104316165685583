import { openDB } from "idb";

const dbPromise = openDB("jt2go-DB1", 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains("userData")) {
      db.createObjectStore("userData");
    }
    if (!db.objectStoreNames.contains("files")) {
      db.createObjectStore("files");
    }
  }
});

const loadUserData = async (key: IDBKeyRange | IDBValidKey) => {
  return (await dbPromise).get("userData", key);
};

const saveUserData = async (
  key: IDBKeyRange | IDBValidKey | undefined,
  newUserData: any
) => {
  return (await dbPromise).put("userData", newUserData, key);
};

const deleteUserData = async (key: IDBKeyRange | IDBValidKey) => {
  return (await dbPromise).delete("userData", key);
};

const loadFile = async (key: IDBKeyRange | IDBValidKey) => {
  return (await dbPromise).get("files", key);
};

const saveFile = async (
  key: IDBKeyRange | IDBValidKey | undefined,
  file: any
) => {
  return (await dbPromise).put("files", file, key);
};

const deleteFile = async (key: IDBKeyRange | IDBValidKey) => {
  return (await dbPromise).delete("files", key);
};

const deleteAllFiles = async () => {
  return (await dbPromise).clear("files");
};

export default {
  loadUserData,
  saveUserData,
  deleteUserData,
  loadFile,
  saveFile,
  deleteFile,
  deleteAllFiles
};
