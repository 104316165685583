import Vue, { nextTick } from "vue";
import Vuex from "vuex";
import i18n from "@/i18n";
import idbs from "@/api/indexedDBService";

// import { CameraMode } from "@com.siemens.plm.web/PLMVisWeb"
const PLMVisWeb = require("@com.siemens.plm.web/PLMVisWeb");
const CameraMode = PLMVisWeb.CameraMode;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    controlManager: null as any,
    touchScreenDevice: false,
    locale: "cn",
    backgroundColor: "#f5f7f6",
    selectionColor: "",
    pmiSelectionColor: "",
    pmiColor: "",
    viewType: CameraMode.ORTHOGRAPHIC,
    recentFiles: [] as any[],
    viewerActive: false,
    cameraActive: false,
    sideDrawer: null,
    selectedItem: {
      psId: "",
      name: "",
      hasPMI: false,
      isAssembly: false
    },
    infoOverlayPosition: "upperRight",
    navCubePosition: "bottomRight",
    triadPosition: "bottomLeft",
    pmiActive: false,
    pmiFiltersMap: {
      Dimension: { psIds: [], selected: true },
      DatumFeatureSymbol: { psIds: [], selected: true },
      FeatureControlFrame: { psIds: [], selected: true },
      MeasurementPoint: { psIds: [], selected: true },
      Geometry: { psIds: [], selected: true },
      Surface: { psIds: [], selected: true },
      LineWeld: { psIds: [], selected: true },
      SpotWeld: { psIds: [], selected: true },
      DatumTarget: { psIds: [], selected: true },
      Note: { psIds: [], selected: true },
      Locators: { psIds: [], selected: true },
      CoordinateSystem: { psIds: [], selected: true },
      Other: { psIds: [], selected: true }
    }
  },
  getters: {
    viewerManager(state) {
      return state?.controlManager?.viewer;
    },
    pmiManager(state) {
      return state?.controlManager?.viewer?.pmi;
    },
    markupManager(state) {
      return state?.controlManager?.viewer?.markup;
    },
    sectionManager(state) {
      return state?.controlManager?.viewer?.section;
    },
    propertiesManager(state) {
      return state?.controlManager?.viewer?.properties;
    },
    snapshotManager(state) {
      return state?.controlManager?.viewer?.snapshot;
    },
    sgoManager(state) {
      return state?.controlManager?.viewer?.sgo;
    }
  },
  mutations: {
    setTouchScreenDevice(state, enabled) {
      state.touchScreenDevice = enabled;
    },
    setLocale(state, locale) {
      // This is a bit ugly, but there doesn't seem to be a good way
      // to attach the locale to the state
      i18n.locale = locale;
      state.locale = locale;
    },
    setBackgroundColor(state, color) {
      state.backgroundColor = color;
    },
    setSelectionColor(state, color) {
      state.selectionColor = color;
    },
    setPmiSelectionColor(state, color) {
      state.pmiSelectionColor = color;
    },
    setPmiColor(state, color) {
      state.pmiColor = color;
    },
    setViewType(state, viewType) {
      state.viewType = viewType;
    },
    setRecentFiles(state, recentFiles) {
      state.recentFiles = recentFiles;
    },
    addRecentFile(state, recentFile) {
      state.recentFiles.push(recentFile);
    },
    removeRecentFile(state, index) {
      state.recentFiles.splice(index, 1);
    },
    removeAllRecentFiles(state) {
      state.recentFiles = [];
    },
    setViewerActive(state, isActive) {
      state.viewerActive = isActive;
    },
    setCameraActive(state, isActive) {
      state.cameraActive = isActive;
    },
    setSideDrawer(state, type) {
      state.sideDrawer = type;
    },
    setControlManager(state, controlManager) {
      state.controlManager = controlManager;
    },
    setSelectedItem(state, item) {
      state.selectedItem = item;
    },
    setInfoOverlayPosition(state, position) {
      state.infoOverlayPosition = position;
    },
    setNavCubePosition(state, position) {
      state.navCubePosition = position;
    },
    setTriadPosition(state, position) {
      state.triadPosition = position;
    },
    setPmiActive(state, isActive) {
      state.pmiActive = isActive;
    },
    setPmiFiltersMap(state, pmiFiltersMap) {
      state.pmiFiltersMap = pmiFiltersMap;
    }
  },
  actions: {
    setLocale(context, locale) {
      idbs.saveUserData("locale", locale);
      context.commit("setLocale", locale);
    },

    setBackgroundColor(context, color) {
      idbs.saveUserData("backgroundColor", color);
      context.commit("setBackgroundColor", color);
    },
    setSelectionColor(context, color) {
      idbs.saveUserData("selectionColor", color);
      context.commit("setSelectionColor", color);
    },
    setPmiSelectionColor(context, color) {
      idbs.saveUserData("pmiSelectionColor", color);
      context.commit("setPmiSelectionColor", color);
    },
    setPmiColor(context, color) {
      idbs.saveUserData("pmiColor", color);
      context.commit("setPmiColor", color);
    },
    setViewType(context, viewType) {
      idbs.saveUserData("viewType", viewType);
      context.commit("setViewType", viewType);
    },
    setInfoOverlayPosition(context, position) {
      idbs.saveUserData("infoOverlayPosition", position);
      context.commit("setInfoOverlayPosition", position);
    },
    setNavCubePosition(context, position) {
      idbs.saveUserData("navCubePosition", position);
      context.commit("setNavCubePosition", position);
    },
    setTriadPosition(context, position) {
      idbs.saveUserData("triadPosition", position);
      context.commit("setTriadPosition", position);
    },
    async loadUserData(context) {
      const idbsPersistantData = [
        { state: "recentFiles", mutation: "setRecentFiles" }
      ];

      const initData = [
        // { state: "cn", mutation: "setLocale" },
        { state: "#00ff00", mutation: "setSelectionColor" }
        // { state: "#f0f8ff", mutation: "setBackgroundColor" }
        // { state: "pmiSelectionColor", mutation: "setPmiSelectionColor" },
        // { state: "pmiColor", mutation: "setPmiColor" },
        // { state: "viewType", mutation: "setViewType" },
        // { state: "recentFiles", mutation: "setRecentFiles" },
        // { state: "infoOverlayPosition", mutation: "setInfoOverlayPosition" },
        // { state: "navCubePosition", mutation: "setNavCubePosition" },
        // { state: "triadPosition", mutation: "setTriadPosition" }
      ];

      idbsPersistantData.forEach(async (field) => {
        const data = await idbs.loadUserData(field.state);
        if (data !== undefined && data !== null) {
          context.commit(field.mutation, data);
        }
      });

      initData.forEach((field) => {
        context.commit(field.mutation, field.state);
      });
    },
    async loadFile(context, fileName) {
      return (await idbs).loadFile(fileName);
    },
    async saveFile(context, recentFile) {
      const length = context.state.recentFiles.length;
      for (let i = 0; i < length; i++) {
        if (context.state.recentFiles[i].name === recentFile.name) {
          context.commit("removeRecentFile", i);
          idbs.saveUserData("recentFiles", context.state.recentFiles);
          break;
        }
      }
      if (recentFile.type === "fileObject") {
        idbs.saveFile(recentFile.name, recentFile.data);
        idbs.saveUserData(
          "recentFiles",
          context.state.recentFiles.concat([
            {
              name: recentFile.name,
              type: recentFile.type,
              thumbnail: recentFile.thumbnail
            }
          ])
        );
        context.commit("addRecentFile", {
          name: recentFile.name,
          type: recentFile.type,
          thumbnail: recentFile.thumbnail
        });
      } else if (recentFile.type === "url") {
        idbs.saveUserData(
          "recentFiles",
          context.state.recentFiles.concat([recentFile])
        );
        context.commit("addRecentFile", recentFile);
      }
    },
    async deleteFile(context, recentFile) {
      const length = context.state.recentFiles.length;
      for (let i = 0; i < length; i++) {
        if (context.state.recentFiles[i].name === recentFile.name) {
          idbs.deleteFile(context.state.recentFiles[i].name);
          context.commit("removeRecentFile", i);
          idbs.saveUserData("recentFiles", context.state.recentFiles);
          break;
        }
      }
    },
    async deleteAllFiles(context) {
      idbs.deleteAllFiles();
      idbs.deleteUserData("recentFiles");
      context.commit("removeAllRecentFiles");
    },
    async restoreDefaultSettings(context) {
      // TODO: May want add these settings to an object in user data that we can just delete althogether
      idbs.deleteUserData("locale");
      idbs.deleteUserData("backgroundColor");
      idbs.deleteUserData("selectionColor");
      idbs.deleteUserData("pmiSelectionColor");
      idbs.deleteUserData("pmiColor");
      idbs.deleteUserData("viewType");
      idbs.deleteUserData("infoOverlayPosition");
      idbs.deleteUserData("navCubePosition");
      idbs.deleteUserData("triadPosition");
      // TODO: Might want to load defaults from config/env file
      context.commit("setLocale", "en-US");
      context.commit("setBackgroundColor", "#ffffff");
      context.commit("setSelectionColor", "#ffff80");
      context.commit("setPmiSelectionColor", "#0000a8");
      context.commit("setPmiColor", "#000000");
      context.commit("setViewType", CameraMode.ORTHOGRAPHIC);
      context.commit("setInfoOverlayPosition", "upperRight");
      context.commit("setNavCubePosition", "bottomRight");
      context.commit("setTriadPosition", "bottomLeft");
    }
  },
  modules: {}
});
