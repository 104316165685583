var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full relative"},[_c('div',{class:['selectedItemInfoOverlay', _vm.infoOverlayPosition]},[_c('span',{class:['itemName', _vm.alignment]},[_vm._v(" "+_vm._s(_vm.selectedItem.name ? _vm.selectedItem.name : _vm.defaultData.name)+" ")]),_c('div',{class:['itemInfoIcons', _vm.alignment]},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
          !(_vm.selectedItem.psId
            ? _vm.selectedItem.isAssembly
            : _vm.defaultData.isAssembly)
        ),expression:"\n          !(selectedItem.psId\n            ? selectedItem.isAssembly\n            : defaultData.isAssembly)\n        "}],staticClass:"infoIcon",attrs:{"src":"/img/toolbar/Part - Black.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.selectedItem.psId ? _vm.selectedItem.isAssembly : _vm.defaultData.isAssembly
        ),expression:"\n          selectedItem.psId ? selectedItem.isAssembly : defaultData.isAssembly\n        "}],staticClass:"infoIcon",attrs:{"src":"/img/toolbar/Assembly - Black.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedItem.psId ? _vm.selectedItem.hasPmi : _vm.defaultData.hasPmi),expression:"selectedItem.psId ? selectedItem.hasPmi : defaultData.hasPmi"}],staticClass:"infoIcon",attrs:{"src":"/img/toolbar/PMI - Black.svg"}})])]),_c('div',{attrs:{"id":"viewerHost"}}),_c('security-dialog',{attrs:{"show":_vm.securityDialog,"message":_vm.securityMessage},on:{"clear":_vm.clearSecurity}}),_c('file-invalid-dialog',{attrs:{"show":_vm.invalidDialog,"message":_vm.invalidMessage},on:{"clear":_vm.clearInvalid}}),_c('load-error-dialog',{attrs:{"show":_vm.loadErrorDialog,"message":_vm.loadErrorMessage},on:{"clear":_vm.clearLoadError}}),(_vm.displayProgressCircle)?_c('div',{staticClass:"flex justify-center items-center h-full"},[_c('svg',{staticClass:"animate-spin h-12 w-12 text-gray-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})])]):_vm._e(),_c('canvas',{ref:"se-navcube",class:_vm.navCubePosition,attrs:{"id":"se-navcube"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }