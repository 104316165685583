<template>
  <div>
    <div class="flex items-center justify-between p-2 mb-4">
      <label class="text-lg font-bold">{{ $t("PMIFiltersTitle") }}</label>
      <img
        @click="setSideDrawer(null)"
        class="h-6 w-6 cursor-pointer"
        src="/img/toolbar/Close.svg"
      />
    </div>
    <div>
      <button>
        <img :src="getSelectAllState" @click="selectAll" />
        <label>{{ $t("SelectAll") }}</label>
      </button>
      <button v-for="(value, key) in pmiFiltersMap" :key="key">
        <img
          :src="
            value.selected
              ? '/img/toolbar/CheckboxChecked.svg'
              : '/img/toolbar/Checkbox.svg'
          "
          @click="toggleSelected(key)"
        />
        <label>{{ key }}({{ value.psIds.length }})</label>
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      numFiltersSelected: 0
    };
  },
  computed: {
    ...mapState(["pmiFiltersMap", "pmiActive"]),
    ...mapGetters(["pmiManager", "viewerManager"]),
    getSelectAllState() {
      if (this.numFiltersSelected === Object.keys(this.pmiFiltersMap).length) {
        return "/img/toolbar/CheckboxChecked.svg";
      } else if (this.numFiltersSelected === 0) {
        return "/img/toolbar/Checkbox.svg";
      } else {
        return "/img/toolbar/CheckboxPartiallyChecked.svg";
      }
    }
  },
  mounted() {
    this.numFiltersSelected = Object.keys(this.pmiFiltersMap).length;
  },
  methods: {
    ...mapMutations(["setSideDrawer", "setTriadPosition"]),
    selectAll() {
      if (this.numFiltersSelected < Object.keys(this.pmiFiltersMap).length) {
        this.numFiltersSelected = Object.keys(this.pmiFiltersMap).length;

        for (var filter1 in this.pmiFiltersMap) {
          this.pmiFiltersMap[filter1].selected = true;

          if (this.pmiFiltersMap[filter1].psIds.length > 0 && this.pmiActive) {
            this.pmiManager.setVisibilityByPsId(
              this.pmiFiltersMap[filter1].psIds,
              true
            );
          }
        }
      } else {
        this.numFiltersSelected = 0;

        for (var filter2 in this.pmiFiltersMap) {
          this.pmiFiltersMap[filter2].selected = false;

          if (this.pmiFiltersMap[filter2].psIds.length > 0 && this.pmiActive) {
            this.pmiManager.setVisibilityByPsId(
              this.pmiFiltersMap[filter2].psIds,
              false
            );
          }
        }
      }
    },
    toggleSelected(key) {
      if (this.pmiFiltersMap[key].selected) {
        this.pmiFiltersMap[key].selected = false;
        this.numFiltersSelected -= 1;

        if (this.pmiActive) {
          this.pmiManager.setVisibilityByPsId(
            this.pmiFiltersMap[key].psIds,
            false
          );
        }
      } else {
        this.pmiFiltersMap[key].selected = true;
        this.numFiltersSelected += 1;

        if (this.pmiActive) {
          this.pmiManager.setVisibilityByPsId(
            this.pmiFiltersMap[key].psIds,
            true
          );
        }
      }
    }
  }
};
</script>
