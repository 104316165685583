<template>
  <div>
    <div class="flex items-center justify-between p-2 mb-4">
      <label class="text-lg font-bold">{{ $t("ModelViewsTitle") }}</label>
      <img
        class="h-6 w-6 cursor-pointer"
        src="/img/toolbar/Close.svg"
        @click="setSideDrawer(null)"
      />
    </div>
    <div>
      <button v-if="headerNode" @click="previousLevel">
        <img
          v-if="headerNode.previousPsId !== '-1'"
          class=""
          src="/img/toolbar/Return.svg"
        />
        <label
          :class="[
            'font-bold',
            headerNode.previousPsId !== '-1' ? '-ml-2' : ''
          ]"
          style="cursor: default"
        >
          {{ headerNode.name }}
        </label>
      </button>
      <button
        v-for="node in nodes"
        :key="node.psId"
        :class="{
          'active-class': node.psId === selectedModelView && !node.children
        }"
        @click="
          node.children
            ? loadModelViewLevel(node.psId)
            : setSelection(node.psId)
        "
      >
        <img
          v-if="thumbnailData && thumbnailData[node.psId]"
          :src="thumbnailData[node.psId]"
          style="width: 5rem; height: 5rem"
        />
        <label>{{ node.name }}</label>
        <img v-if="node.children" class="" src="/img/toolbar/ChevronOpen.svg" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  components: {},
  data: () => ({
    treeMap: new Map(),
    headerNode: null,
    nodes: [],
    previousPsId: "-1",
    selectedModelView: null,
    modelViewData: null,
    thumbnailData: null
  }),
  computed: {
    ...mapState(["touchScreenDevice", "triadPosition"]),
    ...mapGetters(["viewerManager", "snapshotManager", "pmiManager"])
  },
  mounted() {
    this.$root.$on("modelLoaded", this.loadNewModelViews);
  },
  methods: {
    ...mapMutations(["setSideDrawer", "setTriadPosition"]),
    loadNewModelViews(psId) {
      this.treeMap = new Map();
      this.headerNode = null;
      this.nodes = [];
      this.previousPsId = "-1";
      this.selectedModelView = null;
      this.modelViewData = null;
      this.thumbnailData = {};
      this.buildTree(psId);
      this.snapshotManager.enabled = true;
    },
    buildTree: function (psId) {
      var modelViewData = null;
      if (this.viewerManager.modelHasPmi(psId)) {
        modelViewData = this.pmiManager.getModelViewsStructureInfo();
      }
      if (modelViewData !== null) {
        modelViewData = modelViewData.children[0];
        this.treeMap.set(modelViewData.psId, modelViewData);
        this.loadChildren(modelViewData.children);
        this.loadModelViewLevel(modelViewData.psId);
      }
    },
    loadChildren: function (children) {
      for (var child of children) {
        this.treeMap.set(child.psId, child);
        if (child.children) {
          this.loadChildren(child.children);
        }
      }
    },
    loadModelViewLevel: function (psId) {
      if (this.headerNode) {
        this.previousPsId = this.headerNode.psId;
      }
      this.headerNode = this.treeMap.get(psId);
      this.headerNode.previousPsId = this.previousPsId;
      this.nodes = this.headerNode.children;
    },
    previousLevel: function () {
      if (this.headerNode.previousPsId !== "-1") {
        this.headerNode = this.treeMap.get(this.headerNode.previousPsId);
        this.nodes = this.headerNode.children;
      }
    },
    setSelection: function (psId) {
      if (this.selectedModelView !== null) {
        this.pmiManager.setModelViewActive(
          this.selectedModelView,
          false,
          () => {
            if (this.selectedModelView !== psId) {
              this.selectedModelView = psId;
              this.pmiManager.setModelViewActive(psId, true, () => {
                this.takeModelViewSnapshot(psId);
              });
              // this.setSideDrawer(null);
            } else {
              this.selectedModelView = null;
            }
          }
        );
      } else {
        this.selectedModelView = psId;
        this.pmiManager.setModelViewActive(psId, true, () => {
          this.takeModelViewSnapshot(psId);
        });
        // this.setSideDrawer(null);
      }
    },
    takeModelViewSnapshot(psId) {
      var originalTriadPosition = this.triadPosition;
      this.setTriadPosition("notVisible");
      this.$nextTick(() => {
        this.snapshotManager.createSnapshotPNG(
          (snapshot) => {
            if (snapshot) {
              this.$set(this.thumbnailData, psId, snapshot);
            }
            this.setTriadPosition(originalTriadPosition);
          },
          {
            width: 100,
            height: 100,
            showOriginal: true
          }
        );
      });
    }
  }
};
</script>

<style scoped>
.active-class {
  background-color: rgb(61, 133, 164);
}
</style>
