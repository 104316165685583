<template>
  <div>
    <div>
      <label>{{ $t("Settings") }}</label>
      <img @click="setSideDrawer(null)" src="/img/toolbar/Close.svg" />
    </div>

    <div>
      <div>
        <span>{{ $t("Language") }}</span>
        <select>
          <option
            v-for="language in supportedLanguages"
            :key="language.text"
            :value="language.value"
          >
            {{ language.text }}
          </option>
        </select>
      </div>
      <!-- <div>
        <span>{{ $t("InformationOverlayPosition") }}</span>
        <select v-model="infoOverlayPosition" :items="positionOptions"></select>
      </div> -->
      <!-- <div>
        <span>{{ $t("NavCubePosition") }}</span>
        <select v-model="navCubePosition" :items="positionOptions"></select>
      </div> -->
      <!-- <div>
        <span>{{ $t("TriadPosition") }}</span>
        <select v-model="triadPosition" :items="positionOptions"></select>
      </div> -->
      <div>
        <span>{{ $t("BackgroundColor") }}</span>
        <select @change="backgroundColor = $event.target.value">
          <option
            v-for="color in backgroundColorList"
            :value="color.value"
            :key="color.text"
          >
            <div
              style="border: 1px solid black"
              height="24px"
              min-width="24px"
              width="24px"
            >
              <span
                :style="{ background: color.value }"
                style="height: 100%; width: 100%"
              ></span>
            </div>
            <span>{{ $t(color.text) }}</span>
          </option>
        </select>
      </div>
      <div>
        <span>{{ $t("SelectionColor") }}</span>
        <select @change="selectionColor = $event.target.value">
          <option
            v-for="color in selectionColorList"
            :value="color.value"
            :key="color.text"
          >
            <div
              style="border: 1px solid black"
              height="24px"
              min-width="24px"
              width="24px"
            >
              <span
                :style="{ background: color.value }"
                style="height: 100%; width: 100%"
              ></span>
            </div>
            <span>{{ $t(color.text) }}</span>
          </option>
        </select>
      </div>
      <div>
        <span>{{ $t("PMISelectionColor") }}</span>
        <select>
          <option v-for="color in pmiSelectionColorList" :key="color.text">
            <div
              style="border: 1px solid black"
              height="24px"
              min-width="24px"
              width="24px"
            >
              <span
                :style="{ background: color.value }"
                style="height: 100%; width: 100%"
              ></span>
            </div>
            <span>{{ $t(color.text) }}</span>
          </option>
        </select>
      </div>
      <div>
        <span>{{ $t("PMIColor") }}</span>
        <select>
          <option v-for="color in pmiColorList" :key="color.text">
            <div
              style="border: 1px solid black"
              height="24px"
              min-width="24px"
              width="24px"
            >
              <span
                :style="{ background: color.value }"
                style="height: 100%; width: 100%"
              ></span>
            </div>
            <span>{{ $t(color.text) }}</span>
          </option>
        </select>
      </div>
      <div>
        <span>{{ $t("CameraBackground") }}</span>
        <select @change="enableCameraBackground">
          <option
            v-for="cameraBackgroundChoice in cameraBackgroundOptions"
            :value="cameraBackgroundChoice.value"
            :key="cameraBackgroundChoice.text"
          >
            {{ $t(cameraBackgroundChoice.text) }}
          </option>
        </select>
      </div>
      <div>
        <span>{{ $t("ViewType") }}</span>
        <select>
          <option
            v-for="viewType in viewTypes"
            :value="viewType.value"
            :key="viewType.text"
          >
            {{ $t(viewType.text) }}
          </option>
        </select>
      </div>
      <div @click="deleteAllFiles">
        {{ $t("ClearRecentFiles") }}
      </div>
      <div @click="restoreDefaultSettings">
        {{ $t("RestoreDefaultSettings") }}
      </div>
    </div>
  </div>
</template>

<script>
import { ColorList } from "@/constants/constants";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import { CameraMode } from "@com.siemens.plm.web/PLMVisWeb";
// const PLMVisWeb = require("@com.siemens.plm.web/PLMVisWeb");
// const CameraMode = PLMVisWeb.CameraMode;

export default {
  components: {},
  data: () => ({
    supportedLanguages: [
      { text: "English (United States)", value: "en-US" },
      { text: "Deutsch (Deutschland)", value: "de-DE" },
      { text: "français (France)", value: "fr-FR" },
      { text: "italiano (Italia)", value: "it-IT" },
      { text: "čeština (Česká republika)", value: "cs-CZ" },
      { text: "Español (España)", value: "es-ES" },
      { text: "Português (Brasil)", value: "pt-BR" },
      { text: "Magyar (Magyarország)", value: "hu-HU" },
      { text: "Polskie (Polska)", value: "pl-PL" },
      { text: "русский", value: "ru-RU" },
      { text: "日本語", value: "ja-JP" },
      { text: "한국어", value: "ko-KR" },
      { text: "中文(中华人民共和国)", value: "zh-CN" },
      { text: "中文(台灣)", value: "zh-TW" }
    ],
    positionOptions: [
      { text: "Upper Left", value: "upperLeft" },
      { text: "Upper Right", value: "upperRight" },
      { text: "Bottom Left", value: "bottomLeft" },
      { text: "Bottom Right", value: "bottomRight" },
      { text: "Off", value: "notVisible" }
    ],
    defaultBackgroundColor: { text: "Default", value: "#ffffff" },
    defaultSelectionColor: { text: "Default", value: "#ffff80" },
    defaultPmiSelectionColor: { text: "Default", value: "#0000a8" },
    defaultPmiColor: { text: "Default", value: "#000000" },
    cameraBackgroundOptions: [
      { text: "On", value: "On" },
      { text: "Off", value: "Off" }
    ],
    cameraBackgroundChoice: "Off",
    viewTypes: [
      { text: "Orthographic", value: CameraMode.ORTHOGRAPHIC },
      { text: "Perspective", value: CameraMode.PERSPECTIVE }
    ],
    jt2goVersion: process.env.VUE_APP_JT2GO_VERSION
  }),
  computed: {
    ...mapState(["cameraActive"]),
    ...mapGetters(["viewerManager"]),
    locale: {
      get: function () {
        return this.$store.state.locale;
      },
      set: function (newValue) {
        this.$store.dispatch("setLocale", newValue);
      }
    },
    infoOverlayPosition: {
      get: function () {
        return this.$store.state.infoOverlayPosition;
      },
      set: function (newValue) {
        if (this.$store.state.navCubePosition === newValue) {
          this.$store.dispatch(
            "setNavCubePosition",
            this.$store.state.infoOverlayPosition
          );
        } else if (this.$store.state.triadPosition === newValue) {
          this.$store.dispatch(
            "setTriadPosition",
            this.$store.state.infoOverlayPosition
          );
        }
        this.$store.dispatch("setInfoOverlayPosition", newValue);
      }
    },
    navCubePosition: {
      get: function () {
        return this.$store.state.navCubePosition;
      },
      set: function (newValue) {
        if (this.$store.state.infoOverlayPosition === newValue) {
          this.$store.dispatch(
            "setInfoOverlyaPosition",
            this.$store.state.navCubePosition
          );
        } else if (this.$store.state.triadPosition === newValue) {
          this.$store.dispatch(
            "setTriadPosition",
            this.$store.state.navCubePosition
          );
        }
        this.$store.dispatch("setNavCubePosition", newValue);
      }
    },
    triadPosition: {
      get: function () {
        return this.$store.state.triadPosition;
      },
      set: function (newValue) {
        if (this.$store.state.infoOverlayPosition === newValue) {
          this.$store.dispatch(
            "setInfoOverlayPosition",
            this.$store.state.triadPosition
          );
        } else if (this.$store.state.navCubePosition === newValue) {
          this.$store.dispatch(
            "setNavCubePosition",
            this.$store.state.triadPosition
          );
        }
        this.$store.dispatch("setTriadPosition", newValue);
      }
    },
    backgroundColor: {
      get: function () {
        return this.$store.state.backgroundColor;
      },
      set: function (newValue) {
        this.$store.dispatch("setBackgroundColor", newValue);
      }
    },
    selectionColor: {
      get: function () {
        return this.$store.state.selectionColor;
      },
      set: function (newValue) {
        this.$store.dispatch("setSelectionColor", newValue);
      }
    },
    pmiSelectionColor: {
      get: function () {
        return this.$store.state.pmiSelectionColor;
      },
      set: function (newValue) {
        this.$store.dispatch("setPmiSelectionColor", newValue);
      }
    },
    pmiColor: {
      get: function () {
        return this.$store.state.pmiColor;
      },
      set: function (newValue) {
        this.$store.dispatch("setPmiColor", newValue);
      }
    },
    viewType: {
      get: function () {
        return this.$store.state.viewType;
      },
      set: function (newValue) {
        this.$store.dispatch("setViewType", newValue);
      }
    },
    backgroundColorList: function () {
      return [this.defaultBackgroundColor].concat(ColorList);
    },
    selectionColorList: function () {
      return [this.defaultSelectionColor].concat(ColorList);
    },
    pmiSelectionColorList: function () {
      return [this.defaultPmiSelectionColor].concat(ColorList);
    },
    pmiColorList: function () {
      return [this.defaultPmiColor].concat(ColorList);
    }
  },
  mounted: function () {
    this.$root.$on("modelLoaded", this.newModelLoaded);
  },
  methods: {
    ...mapMutations(["setSideDrawer", "setCameraActive"]),
    ...mapActions(["deleteAllFiles", "restoreDefaultSettings"]),
    newModelLoaded: function () {},
    enableCameraBackground: function (value) {
      if (value === "On") {
        this.setCameraActive(true);
      } else {
        this.setCameraActive(false);
      }
    }
  }
};
</script>

<style scoped>
.v-text-field {
  padding-top: 0px;
}
</style>
