<template>
  <div v-show="show">
    <h1 class="text-4xl">
      {{ $t("FileInvalidDialogTitle") }}
    </h1>
    <p class="text-xl my-4">
      {{ message }}
    </p>
    <div>
      <button
        class="underline mr-4 cursor-pointer"
        @click="$emit('clear', 'Back')"
      >
        {{ $t("Back") }}
      </button>
      <button class="underline cursor-pointer" @click="$emit('clear')">
        {{ $t("Continue") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "message"],
  data: () => ({})
};
</script>
