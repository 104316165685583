<template>
  <video ref="video" autoplay playsinline style="object-fit: cover"></video>
</template>

<script>
export default {
  data: () => ({
    mediaStream: null
  }),
  mounted: function () {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: "environment" }
        })
        .then((mediaStream) => {
          this.mediaStream = mediaStream;
          this.$refs.video.srcObject = mediaStream;
          this.$refs.video.play();
        });
    }
  },
  destroyed: function () {
    this.mediaStream.getTracks().forEach(function (track) {
      track.stop();
    });
  }
};
</script>
