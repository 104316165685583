<template>
  <div class="home h-screen w-screen relative flex">
    <aside class="flex-none w-64 h-full px-4">
      <div class="pt-6 pb-12 text-center text-3xl">{{ $t("Viewer") }}</div>
      <div
        class="flex justify-center items-center cursor-pointer"
        @click="$refs.fileInput.click()"
      >
        <img class="w-6 h-6" src="/img/toolbar/Upload.svg" />
        <span class="ml-4">{{ $t("LoadFile") }}</span>
        <input v-show="false" type="file" ref="fileInput" @change="openFile" />
      </div>
    </aside>
    <main class="h-full flex-1 px-12 py-6">
      <!-- Load from URL is disabled for now -->
      <!-- <section>
         <v-col cols="12" xs="12" sm="6" lg="4">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  v-on="on"
                  class="white--text align-end card-tile"
                  height="100px"
                  width="100%"
                  :elevation="hover ? 12 : 2"
                >
                  <v-card-title>{{ $t("LoadFromURL") }}</v-card-title>
                </v-card>
              </v-hover>
            </template>
            <v-card>
              <v-card-title class="text-h5">{{ $t("PleaseEnterURL") }}</v-card-title>
              <v-card-text>
                <v-text-field label="URL" required v-model="urlText"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">{{ $t("Close") }}</v-btn>
                <v-btn color="primary" text @click="openURL(urlText)">{{ $t("Load") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </section> -->

      <label class="text-3xl" v-if="recentFiles.length > 0">
        {{ $t("RecentFiles") }}
      </label>
      <section>
        <div
          class="inline-block relative w-60 h-60"
          v-for="recentFile in recentFiles"
          :key="recentFile.fileName"
          @click="openRecentFile(recentFile)"
        >
          <div
            class="h-full bg-white flex justify-center items-center relative cursor-pointer"
          >
            <img :src="recentFile.thumbnail" class="" />
            <span
              class="absolute bottom-2 w-full text-center overflow-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ recentFile.name }}
            </span>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  data: () => ({
    dialog: false,
    installButtonVisible: false,
    installPrompt: null,
    urlText: null
  }),
  computed: {
    ...mapMutations(["setViewerActive", "setSideDrawer"]),
    ...mapState(["recentFiles"]),
    ...mapGetters(["viewerManager", "snapshotManager"])
  },
  created: function () {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installPrompt = e;
      this.installButtonVisible = true;
    });
  },
  methods: {
    ...mapActions(["loadFile", "saveFile"]),
    home() {
      this.$store.commit("setViewerActive", false);
    },
    install: function () {
      this.installPrompt.prompt();
      this.installPrompt.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          this.installButtonVisible = false;
        }
      });
    },
    openFile: function (event) {
      this.$store.commit("setViewerActive", true);
      this.$root.$emit("open-model", event.target.files[0], () => {
        this.snapshotManager.enabled = true;

        // Error: ResizeObserver loop completed with undelivered notifications.
        this.snapshotManager.createSnapshotPNG(
          (pngData) => {
            this.saveFile({
              name: event.target.files[0].name,
              type: "fileObject",
              data: event.target.files[0],
              thumbnail: pngData
            });
          },
          {
            width: 527,
            height: 348,
            showOriginal: true
          }
        );
      });
    },
    // openURL: function (url) {
    //   if (url !== null) {
    //     this.dialog = false;
    //     this.$store.commit("setViewerActive", true);
    //     this.$root.$emit(
    //       "open-model",
    //       url,
    //       function () {
    //         this.snapshotManager.enabled = true;
    //         this.snapshotManager.createSnapshotPNG(
    //           function (pngData) {
    //             var index = url.lastIndexOf("/");
    //             var name = url.slice(index + 1).replace("|", "/");
    //             this.saveFile({
    //               name: name,
    //               type: "url",
    //               data: url,
    //               thumbnail: pngData
    //             });
    //           }.bind(this),
    //           {
    //             width: 527,
    //             height: 348,
    //             showOriginal: true
    //           }
    //         );
    //       }.bind(this)
    //     );
    //     this.urlText = null;
    //   }
    // },
    openRecentFile(recentFile) {
      if (recentFile.type === "fileObject") {
        this.loadFile(recentFile.name).then((data) => {
          this.$store.commit("setViewerActive", true);
          this.$root.$emit("open-model", data);
        });
      } else if (recentFile.type === "url") {
        this.$store.commit("setViewerActive", true);
        this.$root.$emit("open-model", recentFile.data);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  > aside {
    background-color: var(--background-menu);
  }

  > main {
    overflow: auto;
    background-color: var(--background-base);

    label {
      display: block;
      padding: 3rem 0 1rem;

      &:first-child {
        padding-top: 0;
      }
    }

    section {
      margin: 0 -2px;

      > div {
        padding: 2px;
      }
    }
  }
}
</style>
