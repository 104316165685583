// Copyright 2019 Siemens

import ArrowHandle from "./ArrowHandle.module";

// import { CameraMode, SectionSide, THREE } from "@com.siemens.plm.web/PLMVisWeb";
const PLMVisWeb = require("@com.siemens.plm.web/PLMVisWeb");
const CameraMode = PLMVisWeb.CameraMode;
const SectionSide = PLMVisWeb.SectionSide;
const THREE = PLMVisWeb.THREE;

var _manager = null,
  _mouseDown = false,
  _intersectPoint = null,
  _sectionPlaneSelectionHandle = null,
  _sectionPlaneVisibilityHandle = null;

/**
 * @class SectionHandle
 * @classdesc Represents the arrow with handle.<br><br>
 */
let SectionHandle = function (
  arrowColor,
  handleColor,
  arrowRadius,
  handleRadius
) {
  //NOSONAR
  ArrowHandle.call(this, arrowColor, handleColor, arrowRadius, handleRadius);
  this.type = "SectionHandle";
  this.name = "SectionHandle";
};

SectionHandle.prototype = Object.create(ArrowHandle.prototype);
SectionHandle.prototype.constructor = SectionHandle;

let _getViewCamera = function (viewer) {
  var camInfo = viewer.getCameraInfo();
  if (viewer.getCameraMode() === CameraMode.PERSPECTIVE) {
    var pCamera = new THREE.PerspectiveCamera(
      camInfo.fov,
      camInfo.aspect,
      camInfo.near,
      camInfo.far
    );
    pCamera.position.fromArray(camInfo.pos);
    pCamera.up.fromArray(camInfo.up);
    pCamera.lookAt(new THREE.Vector3().fromArray(camInfo.tgt));
    pCamera.updateMatrixWorld();
    return pCamera;
  } else {
    var oCamera = new THREE.OrthographicCamera(
      camInfo.left,
      camInfo.right,
      camInfo.top,
      camInfo.bottom,
      camInfo.near,
      camInfo.left
    );
    oCamera.position.fromArray(camInfo.pos);
    oCamera.up.fromArray(camInfo.up);
    oCamera.lookAt(new THREE.Vector3().fromArray(camInfo.tgt));
    oCamera.updateMatrixWorld();
    return oCamera;
  }
};

let _setHandlePosition = function (handle, planeId) {
  if (handle.viewer && planeId !== "NONE") {
    var sectionPosition = _manager.getSectionPosition(planeId);

    if (sectionPosition) {
      var planeVector = new THREE.Vector3(
        sectionPosition[0],
        sectionPosition[1],
        sectionPosition[2]
      );
      var camera = _getViewCamera(handle.viewer);
      var vector = planeVector.project(camera);
      var dim = handle.viewer.control.getSize();
      var halfWidth = dim.width / 2,
        halfHeight = dim.height / 2;
      var sectionSide = _manager.getSectionSide(planeId);
      var sectionDirection = _manager.getSectionDirection(planeId);
      if (sectionSide === SectionSide.NEGATIVE) {
        sectionDirection[0] = -sectionDirection[0];
        sectionDirection[1] = -sectionDirection[1];
        sectionDirection[2] = -sectionDirection[2];
      }
      handle.setDirection(
        sectionDirection[0],
        sectionDirection[1],
        sectionDirection[2]
      );
      handle.setPosition(
        Math.round(vector.x * halfWidth + halfWidth),
        Math.round(-vector.y * halfHeight + halfHeight)
      );
    }
  }
};

Object.defineProperties(SectionHandle.prototype, {
  manager: {
    set: function (manager) {
      _manager = manager;
      if (_manager) {
        _sectionPlaneSelectionHandle = function (obj) {
          var sectionPlaneID = obj.selectedPlane;
          if (sectionPlaneID !== "NONE") {
            var planeId = _manager.getSelectedPlane();
            _setHandlePosition(this, planeId);
            var sectionDirection = _manager.getSectionDirection(planeId);
            var sectionSide = _manager.getSectionSide(planeId);
            var sectionBorder = _manager.getBorderVisible(planeId);
            if (sectionSide === SectionSide.NEGATIVE) {
              sectionDirection[0] = -sectionDirection[0];
              sectionDirection[1] = -sectionDirection[1];
              sectionDirection[2] = -sectionDirection[2];
            }
            this.setDirection(
              sectionDirection[0],
              sectionDirection[1],
              sectionDirection[2]
            );
            if (sectionBorder) {
              this.visible = true;
            } else {
              this.visible = false;
            }
          } else {
            this.visible = false;
          }
        }.bind(this);

        _sectionPlaneVisibilityHandle = function (obj) {
          if (
            _manager.getSectionPlaneVisibility(obj.planeId) &&
            _manager.getSelectionById(obj.planeId)
          ) {
            this.visible = true;
          } else {
            this.visible = false;
          }
        }.bind(this);
        _manager.registerPlaneSelectionEvent(_sectionPlaneSelectionHandle);
        _manager.registerPlaneVisibilityEvent(_sectionPlaneVisibilityHandle);
      }
    }
  }
});

SectionHandle.prototype.render = function (renderer, camInfo) {
  if (_manager) {
    _setHandlePosition(this, _manager.getSelectedPlane());
  }

  ArrowHandle.prototype.render.apply(this, arguments);
};

/**
 * Removes attached events
 *
 * @function removeEvents
 * @memberof SectionHandle.prototype
 *
 */
SectionHandle.prototype.removeEvents = function () {
  if (_manager) {
    _manager.unregisterPlaneSelectionEvent(_sectionPlaneSelectionHandle);
    _manager.unregisterPlaneVisibilityEvent(_sectionPlaneVisibilityHandle);
  }

  ArrowHandle.prototype.removeEvents.apply(this, arguments);
};

SectionHandle.prototype.mouseMove = function (event) {
  if (
    event.currentTarget === this.viewer.domElement &&
    _mouseDown &&
    this.viewer &&
    _manager
  ) {
    this.viewer.startRenderLoop();

    var camera = _getViewCamera(this.viewer);
    var viewerPlace = this.viewer.domElement;

    // Need to check event for touch or mouse.
    var ptX, ptY;
    if (event.changedTouches && event.changedTouches.length > 0) {
      let boundRect = this.viewer.domElement.getBoundingClientRect();
      ptX = event.changedTouches[0].pageX - boundRect.x;
      ptY = event.changedTouches[0].pageY - boundRect.y;
    } else {
      ptX = event.offsetX;
      ptY = event.offsetY;
    }

    var pX = (ptX / viewerPlace.width) * 2 - 1;
    var pY = -(ptY / viewerPlace.height) * 2 + 1;

    var mouseVector = new THREE.Vector3(pX, pY, 1);
    mouseVector.unproject(camera);
    var raycaster = new THREE.Raycaster(
      camera.position,
      mouseVector.sub(camera.position).normalize()
    );
    var dragDist = new THREE.Vector3();
    raycaster.ray.closestPointToPoint(_intersectPoint, dragDist);
    dragDist.subVectors(dragDist, _intersectPoint);
    var target = this.viewer.navigation.target;
    var cameraVector = new THREE.Vector3().subVectors(target, camera.position);
    var selectedPlaneId = _manager.getSelectedPlane();
    var sectionD = _manager.getSectionDirection(selectedPlaneId);
    var sectionP = _manager.getSectionPosition(selectedPlaneId);
    var sectionDirection = new THREE.Vector3(
      sectionD[0],
      sectionD[1],
      sectionD[2]
    );
    var sectionPosition = new THREE.Vector3(
      sectionP[0],
      sectionP[1],
      sectionP[2]
    );
    var rads = cameraVector.angleTo(sectionDirection);
    if (rads < Math.PI * 0.1 || rads > Math.PI * 0.9) {
      return; // Angle too severe, drag unpredictable
    }

    // Distance to section plane
    dragDist.projectOnVector(sectionDirection);
    var newPos = new THREE.Vector3().copy(sectionPosition).add(dragDist);
    _manager.setSectionPosition(
      selectedPlaneId,
      newPos.x,
      newPos.y,
      newPos.z,
      false
    );

    _intersectPoint.add(dragDist);
  }
};

SectionHandle.prototype.mouseUp = function (event) {
  // NOSONAR
  if (_mouseDown) {
    // restore default cap/edge states:
    let section = this.viewer.section;
    if (section) {
      section.disableCapsAndEdges(false);
    }

    this.viewer.stopRenderLoop();
    _mouseDown = false;
  }
};

SectionHandle.prototype.mouseDown = function (event) {
  // Need to check event for touch or mouse.
  var ptX, ptY;
  if (event.changedTouches && event.changedTouches.length > 0) {
    let boundRect = this.viewer.domElement.getBoundingClientRect();
    ptX = event.changedTouches[0].pageX - boundRect.x;
    ptY = event.changedTouches[0].pageY - boundRect.y;
  } else {
    ptX = event.offsetX;
    ptY = event.offsetY;
  }

  if (_manager && this.intersect(ptX, ptY)) {
    event.override = true;
    _mouseDown = true;

    // disable cap/edge:
    let section = this.viewer.section;
    if (section) {
      section.disableCapsAndEdges(true);
    }

    var dim = this.viewer.control.getSize();
    var pX = (ptX / dim.width) * 2 - 1;
    var pY = -(ptY / dim.height) * 2 + 1;
    _intersectPoint = new THREE.Vector3(pX, pY, 1).unproject(
      _getViewCamera(this.viewer)
    );
  }
};

export default SectionHandle;
