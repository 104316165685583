<template>
  <div>
    <div class="flex items-center justify-between p-2 mb-4">
      <label class="text-lg font-bold">
        {{ $t("ProductStructureTitle") }}
      </label>
      <img
        @click="setSideDrawer(null)"
        class="h-6 w-6 cursor-pointer"
        src="/img/toolbar/Close.svg"
      />
    </div>
    <div class="">
      <button
        v-if="headerNode"
        :class="{ 'active-class': headerNode.psId === selectedItem.psId }"
      >
        <img
          v-if="headerNode.parentPsId !== '-1'"
          @click="previousLevel"
          class=""
          src="/img/toolbar/Return - Black.svg"
        />

        <img
          v-if="headerNode.parentPsId === '-1'"
          @click="setVisibility(headerNode.psId)"
          class=""
          :src="getVisibilityState(headerNode.psId)"
        />

        <label class="font-bold" style="cursor: default">
          {{ headerNode.name }}
        </label>
      </button>

      <button
        v-for="node in nodes"
        :key="node.psId"
        :class="{ 'active-class': node.psId === selectedItem.psId }"
      >
        <img
          class=""
          @click="setVisibility(node.psId)"
          :src="getVisibilityState(node.psId)"
        />
        <label @click="setSelection(node.psId)">
          {{ node.name }}
        </label>
        <img
          v-if="node.children || node.childrenIds"
          @click="loadPsLevel(node.psId)"
          class=""
          src="/img/toolbar/ChevronOpen.svg"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
// import { AttributeFlag, TriState } from "@com.siemens.plm.web/PLMVisWeb";
const PLMVisWeb = require("@com.siemens.plm.web/PLMVisWeb");
const AttributeFlag = PLMVisWeb.AttributeFlag;
const TriState = PLMVisWeb.TriState;

export default {
  components: {},
  data: () => ({
    treeMap: new Map(),
    headerNode: null,
    nodes: []
  }),
  computed: {
    ...mapState(["touchScreenDevice", "selectedItem"]),
    ...mapGetters(["viewerManager"])
  },
  watch: {
    // selectedItem: function (newItem) {
    //   if (newItem.psId) {
    //     var parent = this.treeMap.get(newItem.psId).parentPsId;
    //     if (
    //       this.headerNode &&
    //       parent &&
    //       this.headerNode.psId !== parent &&
    //       this.headerNode.psId !== newItem.psId
    //     ) {
    //       if (parent === "-1") {
    //         this.loadPsLevel(newItem.psId);
    //       } else {
    //         this.loadPsLevel(parent);
    //       }
    //     }
    //   }
    // }
  },
  mounted: function () {
    this.$root.$on("modelLoaded", this.loadNew);
    // console.log(this.viewerManager);
    // this.viewerManager.registerVisibilityEvent(this.handleVisibilityEvent);
  },
  methods: {
    ...mapMutations(["setSideDrawer", "setSelectedItem"]),
    loadNew: function (psId) {
      this.treeMap = new Map();
      this.headerNode = null;
      this.nodes = [];
      this.buildTree(psId);
      this.viewerManager.registerVisibilityEvent(this.handleVisibilityEvent);
    },
    previousLevel: function () {
      this.headerNode = this.treeMap.get(this.headerNode.parentPsId);
      this.nodes = this.headerNode.children;
    },
    buildTree: function (psId) {
      var psData = this.viewerManager.getProductStructureInfo(
        psId,
        [AttributeFlag.VISIBILITY],
        1
      );

      if (psData !== null) {
        psData.parentPsId = "-1";
        this.treeMap.set(psId, psData);
        if (psData.children) {
          this.loadChildren(psData);
        }
        this.loadPsLevel(psData.psId);
      }
    },
    loadChildren: function (parent) {
      for (var child of parent.children) {
        var childData = this.viewerManager.getProductStructureInfo(
          child.psId,
          [AttributeFlag.VISIBILITY],
          1
        );

        if (childData !== null) {
          childData.parentPsId = parent.psId;
          this.treeMap.set(child.psId, childData);
          if (childData.children) {
            this.loadChildren(childData);
          }
        }
      }
    },
    loadPsLevel: function (psId) {
      this.headerNode = this.treeMap.get(psId);
      this.nodes = this.headerNode.children;
    },
    setVisibility: function (psId) {
      var currentVisibility = this.treeMap.get(psId).visibility;
      if (currentVisibility === TriState.All) {
        this.viewerManager.setVisibilityByPsId(psId, false);
      } else {
        this.viewerManager.setVisibilityByPsId(psId, true);
      }
    },
    getVisibilityState: function (psId) {
      switch (this.treeMap.get(psId).visibility) {
        case TriState.None:
          return "/img/toolbar/Checkbox.svg";
        case TriState.Some:
          return "/img/toolbar/CheckboxPartiallyChecked.svg";
        case TriState.All:
          return "/img/toolbar/CheckboxChecked.svg";
      }
    },
    handleVisibilityEvent: function (visibilityEvent) {
      for (var item of visibilityEvent) {
        if (this.treeMap.get(item.psId)) {
          this.treeMap.get(item.psId).visibility = item.state;
        }
      }
      // Ideally we woudn't have to do this, but
      // I'm not sure how to get Vue's reactivity to cooperate
      this.$forceUpdate();
    },
    setSelection: function (psId) {
      if (this.treeMap.get(this.selectedItem.psId)) {
        this.viewerManager.setSelectionByPsId(this.selectedItem.psId, false);
      }

      if (this.selectedItem.psId === psId) {
        this.viewerManager.setSelectionByPsId(psId, false, true);
      } else {
        if (this.treeMap.get(psId).visibility === TriState.None) {
          this.viewerManager.setVisibilityByPsId(psId, true);
        }
        this.viewerManager.setSelectionByPsId(psId, true, true);
      }
    }
  }
};
</script>

<style scoped></style>
