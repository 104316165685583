// export const IconPaths = Object.freeze({
//   Home: "./img/toolbar/Home.svg",
//   Return: "./img/toolbar/Return.svg",
//   Close: "./img/toolbar/Close.svg",
//   Structure: "./img/toolbar/Structure.svg",
//   ModelView: "./img/toolbar/Modelview.svg",
//   PmiFilters: "./img/toolbar/Filters.svg",
//   Hide: "./img/toolbar/Hide.svg",
//   Part: "./img/toolbar/Part.svg",
//   AssemblyMenu: "./img/toolbar/Assembly.svg",
//   PmiMenu: "./img/toolbar/PMI.svg",
//   Align: "./img/toolbar/AlignToView.svg",
//   Properties: "./img/toolbar/Properties.svg",
//   Bookmark: "./img/toolbar/Snapshot.svg",
//   ShowAll: "./img/toolbar/ShowAllParts.svg",
//   ShowPmi: "./img/toolbar/ShowPMI.svg",
//   Pan: "./img/toolbar/Pan.svg",
//   GeneralMenu: "./img/toolbar/General.svg",
//   Orient: "./img/toolbar/Orient.svg",
//   ViewMenu: "./img/toolbar/ViewMenu.svg",
//   OrientX: "./img/toolbar/OrientToX.svg",
//   OrientY: "./img/toolbar/OrientToY.svg",
//   OrientZ: "./img/toolbar/OrientToZ.svg",
//   Reset: "./img/toolbar/Reset.svg",
//   CrossSectionMenu: "./img/toolbar/CrossSection.svg",
//   CreateCrossSection: "./img/toolbar/CreateCrossSection.svg",
//   CrossSectionX: "./img/toolbar/CrossSectionX.svg",
//   CrossSectionY: "./img/toolbar/CrossSectionY.svg",
//   CrossSectionZ: "./img/toolbar/CrossSectionZ.svg",
//   ClipNone: "./img/toolbar/ClipNone.svg",
//   ClipNear: "./img/toolbar/ClipNear.svg",
//   ClipFar: "./img/toolbar/ClipFar.svg",
//   DeleteCrossSection: "./img/toolbar/DeleteCrossSection.svg",
//   DeleteAllCrossSections: "./img/toolbar/DeleteAllCrossSections.svg",
//   Settings: "./img/toolbar/Settings.svg",
//   Rotate: "./img/toolbar/Spin.svg",
//   Screen: "./img/toolbar/Screen.svg",
//   Fit4: "./img/toolbar/Fit4.svg",
//   Snapshot: "./img/toolbar/TakePicture.svg",
//   CameraOn: "./img/toolbar/CameraOn.svg",
//   PartMenu: "./img/toolbar/PartToolbar.svg",
//   Show: "./img/toolbar/Show.svg",
//   HidePMI: "./img/toolbar/HidePMI.svg",
//   ShowAllPMI: "./img/toolbar/ShowAllPMI.svg",
//   HideAllPMI: "./img/toolbar/HideAllPMI.svg",
//   MarkupMenu: "./img/toolbar/cmdMarkUp24.svg",
//   Freehand: "./img/toolbar/cmdFreehand24.svg",
//   AnchoredText: "./img/toolbar/cmdChangeTextCase24.svg",
//   Erase: "./img/toolbar/cmdErase24.svg",
//   ShowMarkupPanel: "./img/toolbar/cmdMarkUp24.svg",
//   AddMarkupView: "./img/toolbar/cmdAdd24.svg",
//   EditMarkupViewDescription: "./img/toolbar/cmdEdit24.svg",
//   DeleteMarkupView: "./img/toolbar/cmdDelete24.svg",
//   AssemblyTab: "./img/toolbar/Assembly"
// });

export const ColorList = [
  { text: "AliceBlue", value: "#f0f8ff" },
  { text: "AntiqueWhite", value: "#faebd7" },
  { text: "Aqua", value: "#00ffff" },
  { text: "Aquamarine", value: "#7fffd4" },
  { text: "Azure", value: "#f0ffff" },
  { text: "Beige", value: "#f5f5dc" },
  { text: "Bisque", value: "#ffe4c4" },
  { text: "Black", value: "#000000" },
  { text: "BlanchedAlmond", value: "#ffebcd" },
  { text: "Blue", value: "#0000ff" },
  { text: "BlueViolet", value: "#8a2be2" },
  { text: "Brown", value: "#a52a2a" },
  { text: "BurlyWood", value: "#deb887" },
  { text: "CadetBlue", value: "#5f9ea0" },
  { text: "Chartreuse", value: "#7fff00" },
  { text: "Chocolate", value: "#d2691e" },
  { text: "Coral", value: "#ff7f50" },
  { text: "CornflowerBlue", value: "#6495ed" },
  { text: "Cornsilk", value: "#fff8dc" },
  { text: "Crimson", value: "#dc143c" },
  { text: "Cyan", value: "#00ffff" },
  { text: "DarkBlue", value: "#00008b" },
  { text: "DarkCyan", value: "#008b8b" },
  { text: "DarkGoldenrod", value: "#b8860b" },
  { text: "DarkGray", value: "#a9a9a9" },
  { text: "DarkGreen", value: "#006400" },
  { text: "DarkKhaki", value: "#bdb76b" },
  { text: "DarkMagenta", value: "#8b008b" },
  { text: "DarkOliveGreen", value: "#556b2f" },
  { text: "DarkOrange", value: "#ff8c00" },
  { text: "DarkOrchid", value: "#9932cc" },
  { text: "DarkRed", value: "#8b0000" },
  { text: "DarkSalmon", value: "#e9967a" },
  { text: "DarkSeaGreen", value: "#8fbc8f" },
  { text: "DarkSlateBlue", value: "#483d8b" },
  { text: "DarkSlateGray", value: "#2f4f4f" },
  { text: "DarkTurquoise", value: "#00ced1" },
  { text: "DarkViolet", value: "#9400d3" },
  { text: "DeepPink", value: "#ff1493" },
  { text: "DeepSkyBlue", value: "#00bfff" },
  { text: "DimGray", value: "#696969" },
  { text: "DodgerBlue", value: "#1e90ff" },
  { text: "Firebrick", value: "#b22222" },
  { text: "FloralWhite", value: "#fffaf0" },
  { text: "ForestGreen", value: "#228b22" },
  { text: "Fuchsia", value: "#ff00ff" },
  { text: "Gainsboro", value: "#dcdcdc" },
  { text: "GhostWhite", value: "#f8f8ff" },
  { text: "Gold", value: "#ffd700" },
  { text: "Goldenrod", value: "#daa520" },
  { text: "Gray", value: "#bebebe" },
  { text: "Green", value: "#00ff00" },
  { text: "GreenYellow", value: "#adff2f" },
  { text: "Honeydew", value: "#f0fff0" },
  { text: "HotPink", value: "#ff69b4" },
  { text: "IndianRed", value: "#cd5c5c" },
  { text: "Indigo", value: "#4b0082" },
  { text: "Ivory", value: "#fffff0" },
  { text: "Khaki", value: "#f0e68c" },
  { text: "Lavender", value: "#e6e6fa" },
  { text: "LavenderBlush", value: "#fff0f5" },
  { text: "LawnGreen", value: "#7cfc00" },
  { text: "LemonChiffon", value: "#fffacd" },
  { text: "LightBlue", value: "#add8e6" },
  { text: "LightCoral", value: "#f08080" },
  { text: "LightCyan", value: "#e0ffff" },
  { text: "LightGoldenrodYellow", value: "#fafad2" },
  { text: "LightGray", value: "#d3d3d3" },
  { text: "LightGreen", value: "#90ee90" },
  { text: "LightPink", value: "#ffb6c1" },
  { text: "LightSalmon", value: "#ffa07a" },
  { text: "LightSeaGreen", value: "#20b2aa" },
  { text: "LightSkyBlue", value: "#87cefa" },
  { text: "LightSlateGray", value: "#778899" },
  { text: "LightSteelBlue", value: "#b0c4de" },
  { text: "LightYellow", value: "#ffffe0" },
  { text: "Lime", value: "#00ff00" },
  { text: "LimeGreen", value: "#32cd32" },
  { text: "Linen", value: "#faf0e6" },
  { text: "Magenta", value: "#ff00ff" },
  { text: "Maroon", value: "#b03060" },
  { text: "MediumAquamarine", value: "#66cdaa" },
  { text: "MediumBlue", value: "#0000cd" },
  { text: "MediumOrchid", value: "#ba55d3" },
  { text: "MediumPurple", value: "#9370db" },
  { text: "MediumSeaGreen", value: "#3cb371" },
  { text: "MediumSlateBlue", value: "#7b68ee" },
  { text: "MediumSpringGreen", value: "#00fa9a" },
  { text: "MediumTurquoise", value: "#48d1cc" },
  { text: "MediumVioletRed", value: "#c71585" },
  { text: "MidnightBlue", value: "#191970" },
  { text: "MintCream", value: "#f5fffa" },
  { text: "MistyRose", value: "#ffe4e1" },
  { text: "Moccasin", value: "#ffe4b5" },
  { text: "NavajoWhite", value: "#ffdead" },
  { text: "Navy", value: "#000080" },
  { text: "OldLace", value: "#fdf5e6" },
  { text: "Olive", value: "#808000" },
  { text: "OliveDrab", value: "#6b8e23" },
  { text: "Orange", value: "#ffa500" },
  { text: "OrangeRed", value: "#ff4500" },
  { text: "Orchid", value: "#da70d6" },
  { text: "PaleGoldenrod", value: "#eee8aa" },
  { text: "PaleGreen", value: "#98fb98" },
  { text: "PaleTurquoise", value: "#afeeee" },
  { text: "PaleVioletRed", value: "#db7093" },
  { text: "PapayaWhip", value: "#ffefd5" },
  { text: "PeachPuff", value: "#ffdab9" },
  { text: "Peru", value: "#cd853f" },
  { text: "Pink", value: "#ffc0cb" },
  { text: "Plum", value: "#dda0dd" },
  { text: "PowderBlue", value: "#b0e0e6" },
  { text: "Purple", value: "#a020f0" },
  { text: "Red", value: "#ff0000" },
  { text: "RosyBrown", value: "#bc8f8f" },
  { text: "RoyalBlue", value: "#4169e1" },
  { text: "SaddleBrown", value: "#8b4513" },
  { text: "Salmon", value: "#fa8072" },
  { text: "SandyBrown", value: "#f4a460" },
  { text: "SeaGreen", value: "#2e8b57" },
  { text: "SeaShell", value: "#fff5ee" },
  { text: "Sienna", value: "#a0522d" },
  { text: "Silver", value: "#c0c0c0" },
  { text: "SkyBlue", value: "#87ceeb" },
  { text: "SlateBlue", value: "#6a5acd" },
  { text: "SlateGray", value: "#708090" },
  { text: "Snow", value: "#fffafa" },
  { text: "SpringGreen", value: "#00ff7f" },
  { text: "SteelBlue", value: "#4682b4" },
  { text: "Tan", value: "#d2b48c" },
  { text: "Teal", value: "#008080" },
  { text: "Thistle", value: "#d8bfd8" },
  { text: "Tomato", value: "#ff6347" },
  { text: "Turquoise", value: "#40e0d0" },
  { text: "Violet", value: "#ee82ee" },
  { text: "Wheat", value: "#f5deb3" },
  { text: "White", value: "#ffffff" },
  { text: "WhiteSmoke", value: "#f5f5f5" },
  { text: "Yellow", value: "#ffff00" },
  { text: "YellowGreen", value: "#9acd32" }
];
