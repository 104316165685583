<template>
  <div class="w-screen h-screen">
    <home v-show="!viewerActive"></home>
    <viewer-container v-show="viewerActive"></viewer-container>

    <!-- <update-alert></update-alert> -->
    <!-- <legal-dialog :show="legalDialog" @clear="clearLegal"></legal-dialog> -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Home from "./views/Home";
import ViewerContainer from "./views/ViewerContainer";
// import LegalDialog from "./components/LegalDialog";
// import UpdateAlert from "./components/UpdateAlert";

export default {
  components: {
    home: Home,
    "viewer-container": ViewerContainer
    // "legal-dialog": LegalDialog,
    // "update-alert": UpdateAlert
  },
  data: () => ({
    drawer: false,
    legalDialog: false
  }),
  computed: {
    ...mapState(["viewerActive"])
  },
  mounted() {
    if ("maxTouchPoints" in navigator) {
      this.setTouchScreenDevice(navigator.maxTouchPoints > 0);
    } else if ("msMaxTouchPoints" in navigator) {
      this.setTouchScreenDevice(navigator.msMaxTouchPoints > 0);
    }

    this.loadUserData();
  },
  methods: {
    ...mapMutations(["setTouchScreenDevice", "setViewerActive"]),
    ...mapActions(["loadUserData"])
  }
};
</script>

<style></style>
