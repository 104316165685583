<template>
  <div v-show="show">
    <h1 class="text-4xl">
      {{ $t("SecurityDialogTitle") }}
    </h1>
    <p class="text-xl my-4">{{ message }}</p>
    <div>
      <button class="underline mr-4 cursor-pointer" @click="$emit('clear')">
        {{ $t("Acknowledge") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "message"],
  data: () => ({})
};
</script>

<style scoped></style>
