<template>
  <aside
    id="viewerToolbar"
    class="main-menu flex-none h-full w-64 overflow-auto pt-4 px-2"
  >
    <div v-show="mainMenu">
      <button @click="openFilePage">
        <img src="/img/toolbar/Return.svg" />
        <label> {{ $t("Home") }} </label>
      </button>

      <button
        @click="showProductStructure"
        :class="sideDrawer === 'productStructure' ? 'actived' : ''"
      >
        <img src="/img/toolbar/Structure.svg" />
        <label> {{ $t("ProductStructureTooltip") }}</label>
      </button>

      <button
        @click="showModelViews"
        :class="sideDrawer === 'modelViews' ? 'actived' : ''"
      >
        <img src="/img/toolbar/Modelview.svg" />
        <label> {{ $t("ModelViewsTooltip") }} </label>
      </button>

      <button
        @click="showProperties"
        :class="sideDrawer === 'properties' ? 'actived' : ''"
      >
        <img src="/img/toolbar/Properties.svg" />
        <label>{{ $t("PropertiesTooltip") }}</label>
      </button>

      <button @click="() => (pmiMenu ? returnToMainMenu() : showPMIMenu())">
        <img src="/img/toolbar/PMI.svg" />
        <label>{{ $t("PMITooltip") }}</label>
      </button>
      <pmi-menu v-show="pmiMenu" @return="returnToMainMenu" />

      <button
        @click="
          () => (crossSectionMenu ? returnToMainMenu() : showCrossSectionMenu())
        "
      >
        <img src="/img/toolbar/CrossSection.svg" />
        <label> {{ $t("CrossSectionTooltip") }}</label>
      </button>
      <cross-section-menu v-if="crossSectionMenu" @return="returnToMainMenu" />

      <!-- <section  :disabled="!touchScreenDevice">
          <div @click="showMarkupMenu" >
              <img  src="/img/toolbar/cmdMarkUp24.svg" />
              <label>{{ $t("MarkupTooltip") }}</label>
          </div>
       -->

      <button @click="takeSnapshot">
        <img src="/img/toolbar/TakePicture.svg" />
        <label>{{ $t("SnapshotTooltip") }}</label>
      </button>

      <button @click="fit">
        <img src="/img/toolbar/Fit4.svg" />
        <label>{{ $t("FitTooltip") }}</label>
      </button>

      <button
        v-show="showSettingsButton"
        @click="showSettings"
        :class="sideDrawer === 'settings' ? 'actived' : ''"
      >
        <img src="/img/toolbar/Settings.svg" />
        <label>{{ $t("Settings") }}</label>
      </button>
    </div>

    <!-- <markup-menu v-if="markupMenu" @return="returnToMainMenu" /> -->
  </aside>
</template>

<script>
import PmiMenu from "@/components/SubMenus/PmiMenu";
import CrossSectionMenu from "@/components/SubMenus/CrossSectionMenu";
// import MarkupMenu from "@/components/SubMenus/MarkupMenu";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    "pmi-menu": PmiMenu,
    "cross-section-menu": CrossSectionMenu
    // "markup-menu": MarkupMenu
  },
  data: () => ({
    currentMenu: "mainMenu",
    mainMenu: true,
    pmiMenu: false,
    crossSectionMenu: false,
    markupMenu: false,
    windowHeight: document.documentElement.clientHeight
  }),
  computed: {
    ...mapState(["touchScreenDevice", "sideDrawer"]),
    ...mapGetters(["viewerManager", "snapshotManager"]),
    showSettingsButton() {
      return this.$route.query.setting || this.$route.query.settings;
    }
  },
  mounted: function () {
    window.addEventListener("resize", () => {
      this.windowHeight = document.documentElement.clientHeight;
    });
    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        this.windowHeight = document.documentElement.clientHeight;
      }, 250);
    });
  },
  methods: {
    ...mapMutations(["setViewerActive", "setSideDrawer"]),
    // TODO: Look into replacing this menu system with something simpler
    openFilePage: function () {
      this[this.currentMenu] = false;
      this.currentMenu = "mainMenu";
      this.mainMenu = true;
      this.setViewerActive(false);
    },
    showProductStructure: function () {
      if (this.sideDrawer !== "productStructure") {
        this.setSideDrawer("productStructure");
      } else {
        this.setSideDrawer(null);
      }
    },
    showModelViews: function () {
      if (this.sideDrawer !== "modelViews") {
        this.setSideDrawer("modelViews");
      } else {
        this.setSideDrawer(null);
      }
    },
    showProperties: function () {
      if (this.sideDrawer !== "properties") {
        this.setSideDrawer("properties");
      } else {
        this.setSideDrawer(null);
      }
    },
    showPMIMenu: function () {
      this.currentMenu = "pmiMenu";
      // this.mainMenu = false;
      this.pmiMenu = true;
    },
    showCrossSectionMenu: function () {
      this.currentMenu = "crossSectionMenu";
      // this.mainMenu = false;
      this.crossSectionMenu = true;
    },
    // showMarkupMenu: function () {
    //   this.currentMenu = "markupMenu";
    //   this.mainMenu = false;
    //   this.markupMenu = true;
    // },
    takeSnapshot: function () {
      var imgCallback = function (svgUrl) {
        var downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "Snapshot.svg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

      this.snapshotManager.enabled = true;
      this.snapshotManager.createSnapshotSVG(imgCallback);
    },
    fit: function () {
      this.viewerManager.fitToVisible();
    },
    showSettings: function () {
      if (this.sideDrawer !== "settings") {
        this.setSideDrawer("settings");
      } else {
        this.setSideDrawer(null);
      }
    },
    returnToMainMenu: function () {
      this[this.currentMenu] = false;
      this.currentMenu = "mainMenu";
      this.mainMenu = true;
    }
  }
};
</script>

<style lang="less">
.main-menu {
  background-color: var(--background-menu);

  button {
    padding: 0 0.5em;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 1.5em;
      height: 1.5em;
    }

    label {
      text-align: left;
      flex: 1;
      padding: 0 1em;
      cursor: pointer;
    }

    &.actived {
      background-color: var(--background-actived);
      border-radius: 0.5rem;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
