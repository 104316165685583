<template>
  <section id="viewerSideDrawer" class="sideDrawer overflow-y-auto">
    <structure-tree-panel
      v-show="sideDrawer === 'productStructure'"
    ></structure-tree-panel>
    <model-views-panel v-show="sideDrawer === 'modelViews'"></model-views-panel>
    <properties-panel v-show="sideDrawer === 'properties'"></properties-panel>
    <pmi-filters-panel v-show="sideDrawer === 'pmiFilters'"></pmi-filters-panel>
    <!-- <markup-views-panel
      v-show="sideDrawer === 'markupViews'"
    ></markup-views-panel> -->
    <settings-panel v-show="sideDrawer === 'settings'"></settings-panel>
  </section>
</template>

<script>
import StructureTreePanel from "@/components/Panels/StructureTreePanel";
import ModelViewsPanel from "@/components/Panels/ModelViewsPanel";
import PropertiesPanel from "@/components/Panels/PropertiesPanel";
import PmiFiltersPanel from "@/components/Panels/PmiFiltersPanel";
// import MarkupViewsPanel from "@/components/Panels/MarkupViewsPanel";
import SettingsPanel from "@/components/Panels/SettingsPanel";

import { mapState } from "vuex";

export default {
  components: {
    "structure-tree-panel": StructureTreePanel,
    "model-views-panel": ModelViewsPanel,
    "properties-panel": PropertiesPanel,
    "pmi-filters-panel": PmiFiltersPanel,
    // "markup-views-panel": MarkupViewsPanel,
    "settings-panel": SettingsPanel
  },
  data: () => ({}),
  computed: {
    ...mapState(["touchScreenDevice", "sideDrawer"])
  }
};
</script>

<style lang="less">
.sideDrawer {
  width: 16rem;
  height: 100%;
  padding: 0.5rem;
  background-color: var(--background-secondary);

  img {
    width: 1.5em;
    height: 1.5em;
  }

  button {
    width: 100%;
    padding: 0.5em;
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
      text-align: left;
      flex: 1;
      padding: 0 0.5em;
      cursor: pointer;
    }

    &.active-class {
      background-color: var(--background-actived);
      border-radius: 0.5rem;
    }
  }
}
</style>
