<template>
  <div id="viewerContainer" class="h-full flex relative">
    <main-menu />
    <side-drawer v-show="sideDrawer !== null" />
    <camera-background v-if="cameraActive" id="cameraBackground" />
    <main
      class="overflow-auto"
      id="viewerHostContainer"
      :style="{ 'background-color': backgroundColor }"
    >
      <viewer />
    </main>
  </div>
</template>

<script>
import SideDrawer from "@/components/SideDrawer";
import MainMenu from "@/components/MainMenu";
import Viewer from "@/components/Viewer";
import CameraBackground from "@/components/CameraBackground";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    "main-menu": MainMenu,
    viewer: Viewer,
    "camera-background": CameraBackground,
    "side-drawer": SideDrawer
  },
  computed: {
    ...mapState(["backgroundColor", "cameraActive", "sideDrawer"])
  },
  methods: {
    home() {
      this.$store.commit("setViewerActive", false);
    }
  }
};
</script>

<style scoped>
#viewerHostContainer {
  flex: 1;
  padding: 10px;
  background-color: transparent;
}

#cameraBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  z-index: 1;
}
</style>
