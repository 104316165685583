<template>
  <div class="w-full h-full relative">
    <div :class="['selectedItemInfoOverlay', infoOverlayPosition]">
      <span :class="['itemName', alignment]">
        {{ selectedItem.name ? selectedItem.name : defaultData.name }}
      </span>
      <div :class="['itemInfoIcons', alignment]">
        <img
          class="infoIcon"
          v-show="
            !(selectedItem.psId
              ? selectedItem.isAssembly
              : defaultData.isAssembly)
          "
          src="/img/toolbar/Part - Black.svg"
        />
        <img
          class="infoIcon"
          v-show="
            selectedItem.psId ? selectedItem.isAssembly : defaultData.isAssembly
          "
          src="/img/toolbar/Assembly - Black.svg"
        />
        <!-- <image
          class="infoIcon"
          ng-show="vm.currentPart.modelViews.length > 0"
          src="/img/toolbar/Modelview.svg"
        />-->
        <img
          class="infoIcon"
          v-show="selectedItem.psId ? selectedItem.hasPmi : defaultData.hasPmi"
          src="/img/toolbar/PMI - Black.svg"
        />
      </div>
    </div>
    <div id="viewerHost"></div>
    <security-dialog
      :show="securityDialog"
      :message="securityMessage"
      @clear="clearSecurity"
    ></security-dialog>
    <file-invalid-dialog
      :show="invalidDialog"
      :message="invalidMessage"
      @clear="clearInvalid"
    ></file-invalid-dialog>
    <load-error-dialog
      :show="loadErrorDialog"
      :message="loadErrorMessage"
      @clear="clearLoadError"
    ></load-error-dialog>
    <div
      v-if="displayProgressCircle"
      class="flex justify-center items-center h-full"
    >
      <svg
        class="animate-spin h-12 w-12 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
    <!-- Id needs to be se-navcube so the script knows where to attach -->
    <canvas id="se-navcube" ref="se-navcube" :class="navCubePosition"></canvas>
  </div>
</template>

<script>
import WCSTrihedron from "@/assets/SGOs/WCSTrihedron.module.js";
import SecurityDialog from "@/components/SecurityDialog";
import FileInvalidDialog from "@/components/FileInvalidDialog";
import LoadErrorDialog from "@/components/LoadErrorDialog";
import { mapState, mapGetters, mapMutations } from "vuex";

// import { Control, PickingMode } from "@com.siemens.plm.web/PLMVisWeb";
const PLMVisWeb = require("@com.siemens.plm.web/PLMVisWeb");
const Control = PLMVisWeb.Control;
const PickingMode = PLMVisWeb.PickingMode;

// import {
//   PlmVisLoader as JTLoader,
//   JTLoaderEvents
// } from "@com.siemens.vis/jtreader";
const jtreader = require("@com.siemens.vis/jtreader");
const JTLoader = jtreader.PlmVisLoader;
const JTLoaderEvents = jtreader.JTLoaderEvents;

// import { SeNavCube } from "@solidedge/se-navcube";
const { SeNavCube } = require("@solidedge/se-navcube");

export default {
  components: {
    "security-dialog": SecurityDialog,
    "file-invalid-dialog": FileInvalidDialog,
    "load-error-dialog": LoadErrorDialog
  },
  data: () => ({
    displayProgressCircle: false,
    securityDialog: false,
    securityMessage: "",
    invalidDialog: false,
    invalidMessage: "",
    loadErrorDialog: false,
    loadErrorMessage: "",
    defaultData: {
      psId: "",
      name: "",
      hasPmi: false,
      isAssembly: false
    },
    oldTriad: null
  }),
  computed: {
    ...mapState([
      "selectionColor",
      "pmiSelectionColor",
      "pmiColor",
      "viewType",
      "selectedItem",
      "infoOverlayPosition",
      "navCubePosition",
      "triadPosition"
    ]),
    ...mapGetters(["viewerManager", "pmiManager", "sgoManager"]),
    alignment: function () {
      if (
        this.infoOverlayPosition === "upperLeft" ||
        this.infoOverlayPosition === "bottomLeft"
      ) {
        return "alignLeft";
      } else {
        return "alignRight";
      }
    }
  },
  watch: {
    selectionColor(newValue) {
      this.viewerManager.setSelectionMaterial({
        diffuse: parseInt("0x" + newValue.substr(1)),
        ambient: 0,
        emissive: 0,
        specular: 0x7f7f00,
        shininess: 1,
        opacity: 1
      });
    },
    pmiSelectionColor(newValue) {
      this.pmiManager.setPmiSelectionMaterial({
        diffuse: parseInt("0x" + newValue.substr(1)),
        opacity: 1
      });
    },
    pmiColor(newValue) {
      this.pmiManager.setPmiMaterial({
        diffuse: parseInt("0x" + newValue.substr(1)),
        opacity: 1
      });
    },
    viewType(newValue) {
      this.viewerManager.setCameraMode(newValue);
    },
    triadPosition(newValue) {
      this.repositionTriad(newValue);
    }
  },
  created() {
    // Needs to be set on $options to make it unreactive.
    // Setting in data leads to serious performance issues.
    this.$options.controlManager = null;
  },
  mounted() {
    if (this.$options.controlManager === null) {
      this.$options.controlManager = new Control({
        host: document.getElementById("viewerHost")
        // useGpuInstancing: false
      });
    }

    // You have to freeze the manager before storing it so that Vue doesn't make it reactive
    this.setControlManager(Object.freeze(this.$options.controlManager));

    const jtreader = new JTLoader();
    jtreader.addEventListener(
      JTLoaderEvents.SecurityMarkingEvent,
      this.securityHandler
    );
    jtreader.addEventListener(
      JTLoaderEvents.JTFileInvalidEvent,
      this.fileInvalidHandler
    );
    jtreader.addEventListener(
      JTLoaderEvents.JTLoadErrorEvent,
      this.loadErrorHandler
    );

    this.viewerManager.setLoader(jtreader);
    this.viewerManager.registerSelectionEvent(this.handleSelectionEvent);
    this.viewerManager.setPickingMode([PickingMode.PART, PickingMode.PMI]);
    this.viewerManager.setCameraMode(this.viewType);
    this.viewerManager.setDrawWhileLoading(true);
    this.viewerManager.setSelectionToggleMode(true);

    window.addEventListener("resize", this.handleResize);
    // Some browsers don't give correct window sizes in resize event if orientation changed.
    window.addEventListener("orientationchange", this.handleResize);

    this.handleResize();

    this.oldTriad = new WCSTrihedron();
    this.sgoManager.addSGO(this.oldTriad);

    this.seNavCube = new SeNavCube("se-navcube");
    if (this.seNavCube) {
      this.seNavCube.viewer = this.viewerManager;
      // Intercept updates
      if (!this.seNavCube.dispatchUpdate) {
        this.seNavCube.viewer.navigation.seNavCube = this.seNavCube;
        this.seNavCube.dispatchUpdate = this.seNavCube.viewer.navigation.update;
        this.seNavCube.update = function () {
          this.seNavCube.setOrientation(
            this.seNavCube.Math.invert(
              this.seNavCube.viewer.getCameraOrientationInfo().rot
            )
          );
          this.seNavCube.dispatchUpdate();
        };
        this.seNavCube.viewer.navigation.update = this.seNavCube.update;
      }
      // Apply orientation change
      this.seNavCube.addEventListener(
        "change",
        function (event) {
          var orientation = event.orientation;
          var info = this.context.viewer.getCameraOrientationInfo();
          var eye = this.context.Math.normal(
            this.context.Math.decompose(orientation).direction
          );

          info.tgt = [0, 0, 0];
          info.pos = [eye[0], eye[1], eye[2]];
          info.rot = [
            orientation[0],
            orientation[1],
            orientation[2],
            -orientation[3]
          ];

          this.context.viewer.setAutoDraw(false);
          this.context.viewer.setCameraOrientationInfo(info);
          this.context.viewer.fitToVisible();
          this.context.viewer.setAutoDraw(true);

          this.context.viewer.draw();
        },
        this.seNavCube
      );

      // Initialize cube
      this.seNavCube.initialize();
      // For some reason the initialization function resets the height and width to 0
      this.$refs["se-navcube"].height = 128;
      this.$refs["se-navcube"].width = 128;
    }

    // this.cullingManager = this.viewerManager.culling;

    // this.cullingManager.sizeCuller.setUseLoadingUnloading(false);
    // this.cullingManager.sizeCuller.boundaryMovingFrame = 5.0;
    // this.cullingManager.sizeCuller.boundary1 = 3.0;
    // this.cullingManager.sizeCuller.boundary2 = 1.0;
    // this.cullingManager.sizeCuller.useMovingFrameCulling = true;
    // this.cullingManager.setActive(true);

    this.$root.$on("open-model", (model, onVisibleCallback) => {
      if (model !== null) {
        this.openModel(model, onVisibleCallback);
      }
    });
  },
  methods: {
    ...mapMutations([
      "setControlManager",
      "setSelectedItem",
      "setViewerActive"
    ]),
    openModel(file, onVisibleCallback) {
      const openCallback = (success, loadedModelId) => {
        if (success) {
          this.repositionTriad(this.triadPosition);
          // Reset Selected Item
          this.setSelectedItem({
            psId: "",
            name: "",
            hasPmi: false,
            isAssembly: false
          });

          // Create Default Data for hint icons to fallback to when an item isn't selected
          this.defaultData.psId = loadedModelId;
          var psData = this.viewerManager.getProductStructureInfo(
            loadedModelId,
            [],
            0
          );
          this.defaultData.name = psData.name;
          if (psData.childrenIds) {
            this.defaultData.isAssembly = true;
          } else {
            this.defaultData.isAssembly = false;
          }
          this.viewerManager.setVisibilityByPsId(loadedModelId, true, () => {
            this.displayProgressCircle = false;
            this.viewerManager.fitToModel();

            if (onVisibleCallback) {
              onVisibleCallback();
            }

            if (this.viewerManager.modelHasPmi(loadedModelId)) {
              this.pmiManager.loadPmiData(loadedModelId, (pmiSuccess) => {
                if (pmiSuccess === true) {
                  this.defaultData.hasPmi = true;
                  this.$root.$emit("modelLoaded", loadedModelId);
                }
              });
            } else {
              this.defaultData.hasPmi = false;
              this.$root.$emit("modelLoaded", loadedModelId);
            }
          });
        }
      };

      this.displayProgressCircle = true;

      if (typeof file === "string") {
        this.viewerManager.open(file, openCallback);
      } else if (typeof file === "object") {
        this.viewerManager.open(
          { url: file, modelName: file.name },
          openCallback
        );
      }
    },
    handleResize() {
      // document.getElementById("viewerHostContainer").style.height =
      //   document.documentElement.clientHeight + "px";
      // document.getElementById("viewerHostContainer").style.width =
      //   document.documentElement.clientWidth + "px";
      // document.getElementById("viewerToolbar").style.height =
      //   document.documentElement.clientHeight + "px";
      this.repositionTriad(this.triadPosition);
    },
    repositionTriad: function (position) {
      if (this.$options.controlManager && this.sgoManager && this.oldTriad) {
        let triad = this.sgoManager.getSGO("WCSTrihedron");

        if (position !== "notVisible") {
          const size = this.$options.controlManager.getSize();
          let newY;
          let newX;

          if (position === "upperLeft") {
            newY = size["height"] - 120;
            newX = 5;
          } else if (position === "upperRight") {
            newY = size["height"] - 120;
            newX = size["width"] - 120;
          } else if (position === "bottomLeft") {
            newY = 5;
            newX = 5;
          } else if (position === "bottomRight") {
            newY = 5;
            newX = size["width"] - 120;
          }

          if (!triad) {
            this.sgoManager.addSGO(this.oldTriad);
            triad = this.oldTriad;
          }

          triad.setPosition(newX, newY);
        } else {
          if (triad) {
            this.oldTriad = this.sgoManager.getSGO("WCSTrihedron");
            this.sgoManager.removeSGO(triad);
          }
        }
      }
    },
    handleSelectionEvent(psIds) {
      // Turn off previous selection so that only one item is selected at a time
      if (this.selectedItem.psId) {
        this.viewerManager.setSelectionByPsId(
          this.selectedItem.psId,
          false,
          false
        );
      }

      if (psIds.length !== 0) {
        const psId = psIds[psIds.length - 1];
        if (psId === "-1") {
          // Deals with the case where the selection bubbles up to the root and we want to stay in the model
          const testId = psIds[psIds.length - 2];
          this.viewerManager.setSelectionByPsId("-1", false);
          this.viewerManager.setSelectionByPsId(testId, true, true);
        } else {
          const newSelectedItem = { psId };
          const psData = this.viewerManager.getProductStructureInfo(
            psId,
            [],
            0
          );

          if (psData && psData.childrenIds) {
            newSelectedItem.name = psData.name;
            newSelectedItem.isAssembly = true;
          } else {
            newSelectedItem.name = psData.name;
            newSelectedItem.isAssembly = false;
          }

          const pmiData = this.pmiManager.getPmiStructureInfo(psId, [], 0);
          if (pmiData && pmiData.childrenIds) {
            newSelectedItem.hasPmi = true;
          } else {
            newSelectedItem.hasPmi = false;
          }

          this.setSelectedItem(newSelectedItem);
        }
      } else {
        this.setSelectedItem({
          psId: "",
          name: "",
          hasPmi: false,
          isAssembly: false
        });
      }
    },
    securityHandler(event) {
      this.securityDialog = true;
      this.securityMessage = event.message;
    },
    fileInvalidHandler(event) {
      if (this.invalidDialog === true) {
        this.invalidMessage = `${this.invalidMessage}\n${event.file} - ${event.message}`;
      } else {
        this.invalidDialog = true;
        this.invalidMessage = `${event.file} - ${event.message}`;
      }
    },
    loadErrorHandler(event) {
      if (this.loadErrorDialog === true) {
        this.loadErrorMessage = `${this.loadErrorMessage}\n${event.file} - ${event.message}`;
      } else {
        this.loadErrorDialog = true;
        this.loadErrorMessage = `${event.file} - ${event.message}`;
      }
    },
    clearSecurity() {
      this.securityDialog = false;
      this.securityMessage = "";
    },
    clearInvalid() {
      this.invalidDialog = false;
      this.invalidMessage = "";
    },
    clearLoadError(type) {
      this.loadErrorDialog = false;
      this.loadErrorMessage = "";
      if (type === "Back") {
        this.securityDialog = false;
        this.securityMessage = "";
        this.setViewerActive(false);
      }
    }
  }
};
</script>

<style scoped>
.selectedItemInfoOverlay {
  position: absolute;
  z-index: 4;
}

.notVisible {
  display: none;
}

.upperLeft {
  top: 10px;
  left: 15px;
}

.upperRight {
  top: 10px;
  right: 15px;
}

.bottomLeft {
  bottom: 10px;
  left: 15px;
}

.bottomRight {
  bottom: 10px;
  right: 15px;
}

.itemName {
  font-size: 1.25em;
  color: #333;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.itemInfoIcons {
  clear: both;
}

.alignLeft {
  text-align: left;
  float: left;
}

.alignRight {
  text-align: right;
  float: right;
}

.infoIcon {
  height: 24px;
  width: 24px;
  margin: 2px;
}

#viewerHost {
  height: 100%;
  width: 100%;
  position: absolute;
}

.circular_progress_bar {
  position: absolute;
  /* Make sure the margins are half the circles size in order to keep the circle centered */
  left: 50%;
  margin-left: -60px;
  top: 50%;
  margin-top: -60px;
}

#se-navcube {
  background: rgba(0, 0, 0, 0);
  z-index: 4;
  position: absolute;
}
</style>
