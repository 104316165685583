<template>
  <div v-show="show">
    <h1 class="text-4xl">
      {{ $t("LoadErrorDialogTitle") }}
    </h1>
    <p class="my-4 text-xl">
      {{ message }}
    </p>
    <div>
      <button
        class="underline cursor-pointer mr-4"
        @click="$emit('clear', 'Back')"
      >
        {{ $t("Back") }}
      </button>
      <button class="underline cursor-pointer" @click="$emit('clear')">
        {{ $t("Continue") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "message"],
  data: () => ({})
};
</script>

<style scoped></style>
