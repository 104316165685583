import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

// import "roboto-fontface/css/roboto/roboto-fontface.css";
// import "@mdi/font/css/materialdesignicons.css";
import "./assets/css/global.css";
import "./assets/css/tailwind.css";

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h: any) => h(App)
}).$mount("#app");
