// Copyright 2016 Siemens

// import { THREE } from "@com.siemens.plm.web/PLMVisWeb";
const PLMVisWeb = require("@com.siemens.plm.web/PLMVisWeb");
const THREE = PLMVisWeb.THREE;

/**
 * @class WCS Trihedron
 * @classdesc Represents the World Coordinate System (trihedron) for the PLMVisWeb Viewer.<br><br>
 */

var WCSTrihedron = function () {
  //NOSONAR

  // Private variables
  var _object = null,
    _scene = null,
    _camera = null,
    _viewer = null,
    // Default variables to set
    _width = 100,
    _height = 100,
    _positionX = 10,
    _positionY = 10,
    // User-defined variables
    _textNodes = [];

  let _createObject = function () {
    // NOSONAR

    _object = new THREE.Group();
    var geo;

    // Arrows - X
    var xMat = new THREE.LineBasicMaterial({ color: 0xff0000 });
    geo = new THREE.BufferGeometry();

    var positions_array = [0, 0, 0, 20, 0, 0];
    var indices_array = [0, 1];

    geo.setIndex(new THREE.BufferAttribute(new Uint16Array(indices_array), 1));
    geo.addAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(positions_array), 3)
    );

    positions_array.length = 0;
    indices_array.length = 0;

    var arrowX = new THREE.LineSegments(geo, xMat);

    // Arrows - Y
    var yMat = new THREE.LineBasicMaterial({ color: 0x00ff00 });
    geo = new THREE.BufferGeometry();

    positions_array = [0, 0, 0, 0, 20, 0];
    indices_array = [0, 1];

    geo.setIndex(new THREE.BufferAttribute(new Uint16Array(indices_array), 1));
    geo.addAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(positions_array), 3)
    );

    positions_array.length = 0;
    indices_array.length = 0;

    var arrowY = new THREE.LineSegments(geo, yMat);

    // Arrows - Z
    var zMat = new THREE.LineBasicMaterial({ color: 0x0000ff });
    geo = new THREE.BufferGeometry();

    positions_array = [0, 0, 0, 0, 0, 20];
    indices_array = [0, 1];

    geo.setIndex(new THREE.BufferAttribute(new Uint16Array(indices_array), 1));
    geo.addAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(positions_array), 3)
    );

    positions_array.length = 0;
    indices_array.length = 0;

    var arrowZ = new THREE.LineSegments(geo, zMat);

    // Letters - X
    geo = new THREE.BufferGeometry();

    positions_array = [
      1, 4, 0, 2, 4, 0, 3, 4, 0, 4, 4, 0, 1, 1, 0, 2, 1, 0, 3, 1, 0, 4, 1, 0,
      1.5, 1, 0, 3.5, 4, 0, 1.5, 4, 0, 3.5, 1, 0
    ];

    indices_array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    geo.setIndex(new THREE.BufferAttribute(new Uint16Array(indices_array), 1));
    geo.addAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(positions_array), 3)
    );

    positions_array.length = 0;
    indices_array.length = 0;

    var textX = new THREE.LineSegments(geo, xMat);
    textX.position.set(20, 0, 0);

    // Letters - Y
    geo = new THREE.BufferGeometry();

    positions_array = [
      1, 4, 0, 2, 4, 0, 3, 4, 0, 4, 4, 0, 2, 1, 0, 3, 1, 0, 2.5, 1, 0, 2.5, 2.5,
      0, 1.5, 4, 0, 2.5, 2.5, 0, 3.5, 4, 0
    ];

    indices_array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 9, 10];

    geo.setIndex(new THREE.BufferAttribute(new Uint16Array(indices_array), 1));
    geo.addAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(positions_array), 3)
    );

    positions_array.length = 0;
    indices_array.length = 0;

    var textY = new THREE.LineSegments(geo, yMat);
    textY.position.set(0, 20, 0);

    // Letters - Z
    geo = new THREE.BufferGeometry();

    positions_array = [
      1, 3.5, 0, 1, 4, 0, 4, 4, 0, 1, 1, 0, 4, 1, 0, 4, 1.5, 0
    ];

    indices_array = [0, 1, 1, 2, 2, 3, 3, 4, 4, 5];

    geo.setIndex(new THREE.BufferAttribute(new Uint16Array(indices_array), 1));
    geo.addAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(positions_array), 3)
    );

    positions_array.length = 0;
    indices_array.length = 0;

    var textZ = new THREE.LineSegments(geo, zMat);
    textZ.position.set(0, 0, 20);

    _object.add(arrowX);
    _object.add(arrowY);
    _object.add(arrowZ);

    _object.add(textX);
    _object.add(textY);
    _object.add(textZ);

    _textNodes.push(textX);
    _textNodes.push(textY);
    _textNodes.push(textZ);

    xMat = null;
    yMat = null;
    zMat = null;
  };

  let _createScene = function () {
    _scene = new THREE.Scene();
    _width = 100;
    _height = 100;
    _camera = new THREE.OrthographicCamera(
      _width * -0.25,
      _width * 0.25,
      _height * 0.25,
      _height * -0.25,
      -100,
      100
    );

    _scene.add(_camera);
    _scene.add(_object);
  };

  var _WCSTrihedron = function () {
    _createObject();
    _createScene();

    this.name = "WCSTrihedron";
    this.original = _object;
    this.scene = _scene;
    this.camera = _camera;
    this.size = { width: _width, height: _height };
    this.position = { x: _positionX, y: _positionY };
    this.boundingBox = new THREE.Box3().setFromObject(_object);
    this.boundingSphere = new THREE.Sphere();
    this.boundingBox.getBoundingSphere(this.boundingSphere);
    this.visible = true;
    this.onTop = true;

    this.front = true;
    this.original.userData.ignoreRenderMode = true;
  };

  _WCSTrihedron.prototype = {
    constructor: _WCSTrihedron
  };

  Object.defineProperties(_WCSTrihedron.prototype, {
    camera: {
      get: function () {
        return _camera;
      },

      set: function (camera) {
        _camera = camera;
      }
    },

    scene: {
      get: function () {
        return _scene;
      },

      set: function (scene) {
        _scene = scene;
      }
    },

    viewer: {
      set: function (viewer) {
        _viewer = viewer;
      }
    },

    visible: {
      get: function () {
        return _object.visible;
      },

      set: function (value) {
        _object.visible = value;
        if (_viewer) {
          _viewer.draw(false);
        }
      }
    }
  });

  /**
   * Defines how to render the WCSTrihedron
   *
   * @function render
   * @memberof WCSTrihedron.prototype
   *
   */
  _WCSTrihedron.prototype.render = function (renderer, camInfo) {
    renderer.setViewport(
      _positionX,
      renderer.getSize().height - _height - _positionY,
      _width,
      _height
    );

    var camPos = new THREE.Vector3().fromArray(camInfo.pos);

    var tgt = new THREE.Vector3().fromArray(camInfo.tgt);
    camPos.sub(tgt);
    camPos.normalize();

    if (this.boundingSphere) {
      camPos.setLength(this.boundingSphere.radius);
    } else {
      camPos.setLength(50);
    }

    _camera.position.copy(camPos);
    _camera.up.fromArray(camInfo.up);
    _camera.lookAt(_scene.position);

    if (_textNodes && _textNodes.length !== 0) {
      for (var i = 0, len = _textNodes.length; i < len; i++) {
        _textNodes[i].up.copy(_camera.up);
        _textNodes[i].lookAt(
          new THREE.Vector3().addVectors(
            _camera.position,
            _textNodes[i].position
          )
        );
      }
    }

    renderer.render(_scene, _camera);
  };

  /**
   * Handles destruction of this SGO
   *
   * @function dispose
   * @memberof SceneGraphObject.prototype
   */
  _WCSTrihedron.prototype.dispose = function () {
    if (_scene) {
      // remove everythign from scene:
      _scene.children.length = 0;
    }

    // dispose THREE objects:
    if (_object && Array.isArray(_object.children)) {
      for (let i = 0, len = _object.children.length; i < len; ++i) {
        let child = _object.children[i];
        if (child.geometry && child.geometry.dispose) {
          child.geometry.dispose(); // THREE.BufferGeoemetry.dispose
        }
        child.geometry = null;
        if (child.material && child.material.dispose) {
          child.material.dispose(); // THREE.Material.dispose
        }
        child.material = null;
      }
      _object.children.length = 0;
      _object.parent = null;
    }

    // forced disposal:
    delete this.camera;
    _camera = null;
    delete this.original;
    _object = null;
    delete this.scene;
    _scene = null;
    _viewer = null;
  };

  /**
   * Repositions the WCS trihedron.
   *
   * @function setPosition
   * @memberof WCSTrihedron.prototype
   *
   * @param {Number} x - horizontal position in pixels from the bottom left of the viewport.
   * @param {Number} y - vertical position in pixels from the bottom left of the viewport.
   */
  _WCSTrihedron.prototype.setPosition = function (x, y) {
    _positionX = x;
    _positionY = y;
    this.position.x = x;
    this.position.y = y;

    if (_viewer !== null) {
      _viewer.draw(false);
    }
  };

  /**
   * Queries the position of the WCS
   *
   * @function getPosition
   * @memberof WCSTrihedron.prototype
   *
   * @returns {Number[]} represents the X (0) and Y (1) coordinates as measured from the bottom left of the viewport.
   */
  _WCSTrihedron.prototype.getPosition = function () {
    return this.position;
  };

  /**
   * Sets the size of the WCS.
   *
   * @function setSize
   * @memberof WCSTrihedron.prototype
   *
   * @param {Number} w - a number used to set the width of the WCS render area.
   * @param {Number} h - a number used to set the height of the WCS render area.
   */
  _WCSTrihedron.prototype.setSize = function (w, h) {
    _width = w;
    _height = h;
    this.size.width = w;
    this.size.height = h;

    if (_viewer !== null) {
      _viewer.draw(false);
    }
  };

  /**
   * Queries the size of the WCS.
   *
   * @function getSize
   * @memberof WCSTrihedron.prototype
   *
   * @returns {Object} an object that contains the height and width (each as a number) of the WCS render area.
   */
  _WCSTrihedron.prototype.getSize = function () {
    return this.size;
  };

  /**
   * Sets whether the WCS is rendered on top of or behind the scene geometry.
   *
   * @function setOnTop
   * @memberof WCSTrihedron.prototype
   *
   * @params {Boolean} onTop - true if on top, false if behind.
   */
  _WCSTrihedron.prototype.setOnTop = function (onTop) {
    if (this.onTop !== onTop || this.onTop === undefined) {
      this.onTop = onTop;
      if (onTop) {
        this.front = true;
        this.back = false;
      } else {
        this.back = true;
        this.front = false;
      }
      if (_viewer && _viewer.sgo) {
        _viewer.sgo.renderOrderSGO(this);
      }
    }
  };

  /**
   * Queries whether the WCS is rendered on top of or behind the scene geometry.
   *
   * @function getOnTop
   * @memberof WCSTrihedron.prototype
   *
   * @returns {Boolean} true if on top, false if behind.
   */
  _WCSTrihedron.prototype.getOnTop = function () {
    return this.onTop;
  };

  return new _WCSTrihedron();
};

WCSTrihedron.prototype = {
  constructor: WCSTrihedron,
  _name: "WCSTrihedron"
};

self.WCSTrihedron = WCSTrihedron;

export default WCSTrihedron;
