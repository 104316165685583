<template>
  <div>
    <div class="flex items-center justify-between p-2 mb-4">
      <label class="text-lg font-bold">{{ $t("Properties") }}</label>
      <img
        class="h-6 w-6 cursor-pointer"
        src="/img/toolbar/Close.svg"
        @click="setSideDrawer(null)"
      />
    </div>
    <!-- <div class="font-bold p-2">{{ name }}</div> -->
    <ul v-if="properties">
      <li
        v-for="(property, index) in properties"
        :key="index"
        class="px-2 mb-4"
      >
        <div class="font-semibold">{{ property.name }}</div>
        <div class="font-mono break-all">{{ property.value || "-" }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    name: "",
    propertyMap: new Map(),
    properties: null
  }),
  computed: {
    ...mapGetters(["propertiesManager", "viewerManager"])
  },
  mounted() {
    this.$root.$on("modelLoaded", this.newModelLoaded);
  },
  methods: {
    ...mapMutations(["setSideDrawer", "setTriadPosition"]),
    newModelLoaded(psId) {
      this.propertyMap = new Map();
      this.name = "";
      this.properties = null;

      if (this.viewerManager.modelHasPmi(psId)) {
        this.propertiesManager.loadAllProperties(this.loadData(psId));
      }
    },
    loadData(psId) {
      return () => {
        const { name, properties } = this.propertiesManager.getProperties(psId);

        this.name = name;
        if (properties) {
          this.properties = Object.keys(properties).map((key) => ({
            name: key,
            value: properties[key]
          }));
        }
      };
    }
  }
};
</script>
